import { useEffect, useState } from 'react';

export const useScreenSize = () => {
  const [width, setWidth] = useState(undefined);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });

    // Call setState right away so state gets updated with initial window size
    setWidth(window.innerWidth);

    return () => {
      window.removeEventListener('resize', () => {
        setWidth(window.innerWidth);
      });
    };
  }, []);
  return width;
};
