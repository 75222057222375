import styled from '@emotion/styled';
import { theme, Typography as ClipTypography } from '@clipmx/clip-components';

export const SwipeableTitle = styled(ClipTypography)`
  text-align: 'center';
  color: ${theme.palette.surface[950]};
  font-size: ${theme.typography.lg.fontSize};
  line-height: ${theme.typography.lg.lineHeight};
  font-weight: ${theme.typography.fontWeightMedium};
  margin: 0;

  ${[theme.breakpoints.down('tablet')]} {
    font-size: ${theme.typography.xl.fontSize};
    line-height: ${theme.typography.xl.lineHeight};
  }
`;

export const SwipeableDescription = styled(ClipTypography)`
  color: ${theme.palette.surface[700]};
  font-size: ${theme.typography.body1.fontSize};
  font-weight: ${theme.typography.fontWeightRegular};
  line-height: ${theme.typography.body1.lineHeight};
  margin-top: 8;

  ${[theme.breakpoints.down('tablet')]} {
    font-size: ${theme.typography.sm.fontSize};
    line-height: ${theme.typography.sm.lineHeight};
  }
`;

export const SwipeableActionContainer = styled.div`
  display: flex;
  padding-top: 24px;
`;

export const ConfirmContainer = styled.div`
  padding: 24px 16px;
`;
