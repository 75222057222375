import styled from '@emotion/styled';
import {
  theme,
  Typography as ClipTypography,
  IconButton as ClipIconButton
} from '@clipmx/clip-components';
import { importantCSS } from 'helpers/overwriteCSS';

export const CardViewContainerAdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
`;
export const LoadingContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 30px;
`;
export const ClipTypographyTitle = styled(ClipTypography)`
  ${importantCSS(theme.typography.lg)};

  ${theme.breakpoints.down('tablet')} {
    ${importantCSS(theme.typography.sm)}
  }
`;

export const ClipTypographySubtitle = styled(ClipTypography)`
  ${importantCSS(theme.typography.body1)};
  margin-bottom: 32px;

  ${theme.breakpoints.down('tablet')} {
    ${importantCSS(theme.typography.sm)};
    margin-bottom: 24px;
  }
`;

export const ClipTypographyAddLabel = styled(ClipTypography)`
  ${importantCSS({
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightMedium
  })};

  ${theme.breakpoints.down('tablet')} {
    ${importantCSS(theme.typography.sm)}
  }
`;

export const ClipIconButtonAddNew = styled(ClipIconButton)`
  width: 100%;
  height: ${theme.spacing(15)};
  display: flex;
  justify-content: space-between;
`;
