import productsService from 'services/products';
import {
  errorProducts,
  getAllProducts,
  requestProducts,
  responseProducts,
  requestSelectedProduct,
  responseSelectedProduct,
  setSelectedProduct,
  setSelectedProductError
} from './productsSlice';

export const getAllProductsThunk = () => (dispatch) => {
  dispatch(errorProducts({ found: false, message: '', code: 418 }));
  dispatch(requestProducts());

  productsService
    .getAll()
    .then((res) => {
      dispatch(getAllProducts(res.data));
    })
    .catch((err) => {
      dispatch(errorProducts(err));
    })
    .finally(() => {
      dispatch(responseProducts());
    });
};
export const addNewProductThunk =
  (product, onSuccess, onFailure) => (dispatch, getState) => {
    dispatch(requestProducts());

    productsService
      .addNew(product)
      .then((res) => {
        const newProduct = res.data;
        const currentProducts = getState().products.items;
        dispatch(getAllProducts([...currentProducts, newProduct]));
        onSuccess?.(newProduct);
      })
      .catch((err) => {
        dispatch(errorProducts(err));
        onFailure?.();
      })
      .finally(() => {
        dispatch(responseProducts());
      });
  };

export const getProductByIdThunk =
  (productId, onSuccess, onFailure) => (dispatch) => {
    dispatch(requestSelectedProduct());

    productsService
      .getById(productId)
      .then((res) => {
        dispatch(setSelectedProduct(res.data));
        onSuccess?.();
      })
      .catch((err) => {
        dispatch(setSelectedProductError(err));
        onFailure?.();
      })
      .finally(() => {
        dispatch(responseSelectedProduct());
      });
  };

export const updateProductByIdThunk =
  (product, onSuccess, onFailure) => (dispatch) => {
    dispatch(requestSelectedProduct());

    productsService
      .updateById(product)
      .then(() => {
        onSuccess?.();
      })
      .catch(() => {
        onFailure?.();
      })
      .finally(() => {
        dispatch(responseSelectedProduct());
      });
  };
