import {
  theme,
  Typography as ClipTypography,
  Input as ClipInput
} from '@clipmx/clip-components';
import styled from '@emotion/styled';
import { importantCSS } from 'helpers/overwriteCSS';

export const ClipTypographyTitle = styled(ClipTypography)`
  ${importantCSS({
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightMedium
  })}
  margin-bottom: 24px;
`;

export const ClipTypographySubtitle = styled(ClipTypography)`
  ${importantCSS({ ...theme.typography.body1 })}
  margin-bottom: 24px;

  ${theme.breakpoints.down('tablet')} {
    ${importantCSS({
      ...theme.typography.sm
    })}
  }
`;

export const Divider = styled.div`
  height: 40px;
  margin-right: 16px;

  ${theme.breakpoints.down('tablet')} {
    height: 16px;
    margin-right: 0;
  }
`;

export const InvertoryInputContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${theme.breakpoints.down('tablet')} {
    flex-direction: column;
  }
`;

export const ClipInputStock = styled(ClipInput)`
  /* remove arrow spinners in Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* remove arrow spinners in Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  ${theme.breakpoints.down('tablet')} {
    margin-right: 0;
  }
`;

export const ClipInputStockAlert = styled(ClipInput)`
  /* remove arrow spinners in Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* remove arrow spinners in Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
