import { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { theme, Dialog as ClipDialog } from '@clipmx/clip-components';
import { useScreenSize } from 'helpers/hooks/useScreenSize';
import { SwipeableDrawer } from 'components/SwipeableDrawer/SwipeableDrawer';
import {
  ADD_ATTRIBUTE_VIEW,
  ADD_ATTRIBUTE_VARIANT_VIEW
} from 'components/ProductContent/constants';
import { defaultOptions } from 'helpers/utils';
import { withFormik } from 'formik';
import {
  attributeNameValidator,
  attributeVariantValidator
} from 'helpers/fieldValidations';
import { text } from './text';
import { AttributesDialogContent } from './AttributesDialogContent';
import { AttributeWithVariantsDialogContent } from './AttributeWithVariantsDialogContent';
import { VariantsDialogContent } from './VariantsDialogContent';
import { SwipeableContent } from './styled';

export const AddAttribute = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    resetForm,
    submitForm,
    open,
    handleAddProductOptions,
    handleModal,
    variants,
    setVariants
  } = props;

  const [view, setView] = useState(ADD_ATTRIBUTE_VARIANT_VIEW);
  const [optionName, setOptionName] = useState('');
  const [availableOptions, setAvailableOptions] = useState(
    Object.keys(defaultOptions)
  );
  const availableVariants = useMemo(
    () =>
      defaultOptions?.[optionName]?.filter(
        (variant) => !variants.includes(variant)
      ) ?? [],
    [variants, optionName]
  );

  const width = useScreenSize();
  const isMobile = width <= theme.breakpoints.values.tablet;

  const handleClose = () => {
    setOptionName('');
    handleModal(false);
    setVariants([]);
    resetForm();
  };

  const handleListItemClick = (name) => {
    handleChangeOptionName(name);
    setVariants([]);
  };

  const filterDefaulOptions = (name) => {
    setAvailableOptions((prevOptions) => [
      ...prevOptions.filter((item) => item !== name)
    ]);
  };

  const handleChangeDialog = (currentDialog) => {
    setView(currentDialog);
  };

  const backToInitialDialog = () => {
    setView(ADD_ATTRIBUTE_VARIANT_VIEW);
    resetForm();
  };

  const handleChangeOptionName = (name) => {
    setOptionName(name);
    setView(ADD_ATTRIBUTE_VARIANT_VIEW);
  };

  const handleChangeVariants = (variantName) => {
    !variants.includes(variantName) &&
      setVariants((prev) => [...prev, variantName]);
    setView(ADD_ATTRIBUTE_VARIANT_VIEW);
    resetForm();
  };

  const handleDeletevariant = (variantName) => {
    setVariants((prevVariants) => [
      ...prevVariants.filter((variant) => variant !== variantName)
    ]);
  };

  let dialogContent = null;
  if (view === ADD_ATTRIBUTE_VARIANT_VIEW) {
    dialogContent = (
      <AttributeWithVariantsDialogContent
        handleChangeDialog={handleChangeDialog}
        optionName={optionName}
        variants={variants}
        handleAddProductOptions={handleAddProductOptions}
        handleDeletevariant={handleDeletevariant}
        filterDefaulOptions={filterDefaulOptions}
        handleClose={handleClose}
        submitForm={submitForm}
      />
    );
  } else if (view === ADD_ATTRIBUTE_VIEW) {
    dialogContent = (
      <AttributesDialogContent
        backToInitialDialog={backToInitialDialog}
        title={text.atribute.title}
        message={text.atribute.message}
        label={text.atribute.addLabel}
        handleChangeOptionName={handleChangeOptionName}
        values={values}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
        handleBlur={handleBlur}
        handleListItemClick={handleListItemClick}
        availableOptions={availableOptions}
      />
    );
  } else {
    dialogContent = (
      <VariantsDialogContent
        backToInitialDialog={backToInitialDialog}
        title={text.variant.title}
        message={text.variant.message}
        label={text.variant.addLabel}
        optionName={optionName}
        handleChangeVariants={handleChangeVariants}
        values={values}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
        handleBlur={handleBlur}
        availableVariants={availableVariants}
      />
    );
  }

  return !isMobile ? (
    <ClipDialog open={open}>{dialogContent}</ClipDialog>
  ) : (
    <SwipeableDrawer
      open={open}
      onClose={() => handleModal(false)}
      onOpen={() => handleModal(true)}
    >
      <SwipeableContent>{dialogContent}</SwipeableContent>
    </SwipeableDrawer>
  );
};
const validationSchema = Yup.object({
  attribute: attributeNameValidator,
  variant: attributeVariantValidator
});

export const AddAtributteContainer = withFormik({
  mapPropsToValues: () => ({
    attribute: '',
    variant: ''
  }),
  validate: (values, props) => {
    const errors = {};
    if (values.variant && props.variants.indexOf(values.variant) !== -1) {
      errors.variant = text.variantsMessageSameName;
    }
    if (
      values.attribute &&
      !!props.productOptions.find((item) => item.name === values.attribute)
    ) {
      errors.attribute = text.optionMessageSameName;
    }
    return errors;
  },
  handleSubmit: () => null,
  validationSchema,
  displayName: 'AddAtributteContainer',
  enableReinitialize: true
})(AddAttribute);
