import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import {
  Button as ClipButton,
  Card as ClipCard,
  CardContent as ClipCardContent,
  theme,
  Typography as ClipTypography
} from '@clipmx/clip-components';
import { importantCSS } from 'helpers/overwriteCSS';

const MOBILE_BAR_HEIGHT = '153px';
const MOBILE_ANIMATION_TIME = '0.2s';
const DESKTOP_BAR_HEIGHT = '72px';
const DESKTOP_ANIMATION_TIME = '0.1s';

const OnEnterAnimation = (isMobile = false) => keyframes`
  0% {
    bottom: -${isMobile ? MOBILE_BAR_HEIGHT : DESKTOP_BAR_HEIGHT};
  }
  100% {
    bottom: 0;
  }
`;

const OnExitAnimation = (isMobile = false) => keyframes`
  0% {
    bottom: 0;
  }
  100% {
    bottom: -${isMobile ? MOBILE_BAR_HEIGHT : DESKTOP_BAR_HEIGHT};
  }
`;

export const getAnimatedValues = ({ isVisible = false, isMobile = false }) => ({
  animation: `
    ${
      isVisible ? OnEnterAnimation(isMobile) : OnExitAnimation(isMobile)
    }  cubic-bezier(0.4, 0, 0.2, 1) ${
    isMobile ? MOBILE_ANIMATION_TIME : DESKTOP_ANIMATION_TIME
  }
  `,
  bottom: isVisible
    ? 0
    : `-${isMobile ? MOBILE_BAR_HEIGHT : DESKTOP_BAR_HEIGHT}`
});

export const SelectedItemsFixedContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  ${(props) => ({ ...props.sx })};
`;

export const SelectedItemsChild = styled(ClipCard)`
  ${importantCSS({
    alignItems: 'center',
    backgroundColor: `${theme.palette.surface[950]}`,
    color: `${theme.palette.surface[50]}`,
    display: 'flex',
    height: '56px',
    justifyContent: 'space-between',
    minWidth: '470px',
    width: '30%',
    maxWidth: '70%'
  })}
`;

export const ClipTypographyBottomBarTitle = styled(ClipTypography)`
  ${importantCSS(theme.typography.sm)}
  color: ${theme.palette.surface[50]};
  line-height: 16px;
  font-weight: ${theme.typography.fontWeightBold};
`;

export const ItemSizeStyled = styled.div`
  display: inline-block;
  background: ${theme.palette.surface[200]};
  height: 24px;
  width: 24px;
  line-height: 24px;
  color: ${theme.palette.surface[700]};
  text-align: center;
  border-radius: 50%;
  margin-right: 10px;
`;

export const ClipCardContentStyled = styled(ClipCardContent)`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

export const ClipButtonStyled = styled(ClipButton)`
  ${theme.typography.body1}
  font-weight: ${theme.typography.fontWeightMedium};
  white-space: nowrap;
  padding: 0 32px;

  ${theme.breakpoints.down('tablet')} {
    color: ${theme.palette.surface[700]};
    background-color: ${theme.palette.surface[100]};
    ${theme.typography.sm}
    padding: 0;
    width: 100%;
  }
`;

export const ClipCardBottomStyled = styled(ClipCard)`
  ${importantCSS({
    backgroundColor: `${theme.palette.surface[950]}`,
    boxShadow: 'none',
    display: 'flex',
    position: 'fixed',
    flexDirection: 'column',
    justifyItems: 'center',
    justifyContent: 'end',
    width: '100%',
    borderRadius: `${theme.spacing(2)} ${theme.spacing(2)} 0 0`
  })}
`;

export const Divider = styled.div`
  height: 24px;

  ${theme.breakpoints.down('tablet')} {
    height: 32px;
  }
`;

export const MobileButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: ${theme.spacing(4)};
`;
