import {
  theme,
  Card as ClipCard,
  Input as ClipInput,
  Typography as ClipTypography,
  IconButton as ClipIconButton,
  Chip as ClipChip,
  ListItemButton as ClipListItemButton,
  ListItemText as ClipListItemText,
  Dialog as ClipDialog,
  Button as ClipButton
} from '@clipmx/clip-components';
import { PlusAdd, Close } from '@clipmx/clip-icons';
import { useScreenSize } from 'helpers/hooks/useScreenSize';
import { ImagePreview } from 'components/ImagePreview/ImagePreview';
import { hasError } from 'helpers/fieldValidations';
import { InputPrice } from 'components/InputPrice/InputPrice';
import { Autocomplete } from 'components/Autocomplete/Autocomplete';
import {
  MAX_CATEGORIES,
  MAX_CATEGORIES_FROM_EDIT
} from 'components/ProductContent/constants';
import { useSelector } from 'react-redux';
import { selectCategories } from 'store/categories/selectors';
import { useState } from 'react';
import { SwipeableDrawer } from 'components/SwipeableDrawer/SwipeableDrawer';
import {
  ClipIconButtonAddCategory,
  InformationContainer,
  ClipCardContentStyled,
  FieldsContainer,
  Space,
  AddProductForm,
  SwipeableInputContainer,
  SwipeableListContainer,
  SwipeableTitle,
  SwipeableDescription,
  SwipeableActionContainer,
  ConfirmContainer
} from './styled';
import { text } from './text';

export const AddProduct = (props) => {
  const {
    values,
    handleChange,
    handleBlur,
    handleUpload,
    errors,
    touched,
    productCategories,
    onSetProductCategory,
    isEdit,
    imageSrc,
    currentCategory
  } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [openSwipeable, setOpenSwipeable] = useState(false);
  const [removeCategory, setRemoveCategory] = useState(null);
  const categories = useSelector(selectCategories);
  const width = useScreenSize();
  const currentCategoryName = currentCategory?.category_name;

  const validateInputSize = () => {
    let breakPointSize = theme.breakpoints.values.tablet;
    return width > breakPointSize ? 'medium' : 'small';
  };

  const handleSwipeable = () => {
    setOpenSwipeable((prev) => !prev);
  };

  const handleAssignCategory = (isMobile = false) => {
    values.productCategory &&
      !errors.productCategory &&
      onSetProductCategory(values.productCategory);
    isMobile && !errors.productCategory && handleSwipeable();
  };

  const handleSelectItemButton = (category) => {
    onSetProductCategory(category);
    handleSwipeable();
  };

  const handleAssignCategoryKeyDown = (ev, isMobile = false) => {
    ev.key === 'Enter' && handleAssignCategory(isMobile);
  };

  const validateAddCategoryComponent = () => {
    const isMobile = width <= theme.breakpoints.values.tablet;
    const isDisabled =
      productCategories.length === MAX_CATEGORIES ||
      (productCategories.length === MAX_CATEGORIES_FROM_EDIT &&
        !currentCategoryName &&
        !!currentCategory); // there is a case where the category name could be empty

    return isMobile ? (
      <>
        <ClipIconButtonAddCategory
          disabled={isDisabled}
          color="transparent"
          variant="rounded"
          onClick={handleSwipeable}
        >
          <ClipTypography
            sx={{ fontWeight: theme.typography.fontWeightMedium }}
          >
            {text.addACategory}
          </ClipTypography>
          <PlusAdd
            sx={{ color: theme.palette.primary['600'], PointerEvent: 'none' }}
          />
        </ClipIconButtonAddCategory>
        <SwipeableDrawer
          open={openSwipeable}
          onClose={handleSwipeable}
          onOpen={handleSwipeable}
        >
          <SwipeableInputContainer>
            <ClipInput
              autoFocus={!categories.length}
              name="productCategory"
              label={text.addNewCategory}
              value={values.productCategory}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={(ev) => handleAssignCategoryKeyDown(ev, true)}
              error={hasError('productCategory', errors, touched)}
              helperText={
                hasError('productCategory', errors, touched) &&
                errors.productCategory
              }
              sx={{ marginRight: theme.spacing(2) }}
            />
            <ClipIconButton
              color="primary"
              variant="rounded"
              onClick={() => handleAssignCategory(isMobile)}
            >
              <PlusAdd sx={{ pointerEvents: 'none' }} />
            </ClipIconButton>
          </SwipeableInputContainer>
          <SwipeableListContainer>
            {categories.map((category) => (
              <ClipListItemButton
                key={category.category_id}
                sx={{
                  padding: `${theme.spacing(5)} ${theme.spacing(6)}`,
                  overflowX: 'hidden',
                  display:
                    productCategories.includes(category.category_name) && 'none'
                }}
                onClick={() => handleSelectItemButton(category.category_name)}
              >
                <ClipListItemText
                  primaryTypographyProps={{
                    color: `${theme.palette.surface[700]} !important`
                  }}
                  primary={category.category_name}
                />
              </ClipListItemButton>
            ))}
          </SwipeableListContainer>
        </SwipeableDrawer>
      </>
    ) : (
      <Autocomplete
        options={{
          data: categories,
          optionKey: 'category_id',
          optionLabel: 'category_name'
        }}
        onSelectAction={onSetProductCategory}
        selectedOptions={productCategories}
      >
        <ClipInput
          disabled={isDisabled}
          size={validateInputSize()}
          sx={(theme) => ({
            [theme.breakpoints.down('tablet')]: {
              border: 'none'
            }
          })}
          name="productCategory"
          value={values.productCategory}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleAssignCategoryKeyDown}
          label={text.assingCategory}
          error={hasError('productCategory', errors, touched)}
          helperText={
            hasError('productCategory', errors, touched) &&
            errors.productCategory
          }
          endAdornment={
            <ClipIconButton
              disabled={isDisabled}
              color="transparent"
              variant="circular"
              onClick={handleAssignCategory}
            >
              <PlusAdd />
            </ClipIconButton>
          }
        />
      </Autocomplete>
    );
  };

  const handleOpenDialog = (category, index) => {
    setOpenDialog(true);
    setRemoveCategory({ name: category, index });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setRemoveCategory(null);
  };

  const handleDeleteCategory = () => {
    onSetProductCategory(removeCategory.name, removeCategory.index);
    handleCloseDialog();
  };

  const validateDeleteConfirm = () => {
    const isMobile = width <= theme.breakpoints.values.tablet;

    return isMobile ? (
      <SwipeableDrawer
        open={openDialog}
        onClose={handleCloseDialog}
        onOpen={() => null}
      >
        <ConfirmContainer>
          <SwipeableTitle>{text.dialog.title}</SwipeableTitle>
          <SwipeableDescription>{text.dialog.description}</SwipeableDescription>
          <SwipeableActionContainer>
            <ClipButton
              aria-label="button-secondary"
              variant="contained"
              size="small"
              sx={{ flexGrow: 1, mr: 1 }}
              color="tertiary"
              onClick={handleCloseDialog}
            >
              {text.dialog.secondaryLabelMobile}
            </ClipButton>
            <ClipButton
              variant="contained"
              aria-label="button-primary"
              size="small"
              sx={{ flexGrow: 1, ml: 1 }}
              color="primary"
              onClick={handleDeleteCategory}
            >
              {text.dialog.primaryLabel}
            </ClipButton>
          </SwipeableActionContainer>
        </ConfirmContainer>
      </SwipeableDrawer>
    ) : (
      <ClipDialog
        open={openDialog}
        onClose={handleCloseDialog}
        title={text.dialog.title}
        description={text.dialog.description}
        primaryButtonLabel={text.dialog.primaryLabel}
        primaryButtonAction={handleDeleteCategory}
        secondaryButtonLabel={text.dialog.secondaryLabel}
        secondaryButtonAction={handleCloseDialog}
      />
    );
  };

  return (
    <>
      <ClipCard
        bgColorVariant="50"
        borderRadius={2}
        sx={(theme) => ({
          [theme.breakpoints.down('tablet')]: {
            backgroundColor: theme.palette.surface[50],
            boxShadow: 'none'
          }
        })}
      >
        <ClipCardContentStyled>
          <ClipTypography
            color="surface.600"
            sx={(theme) => ({
              fontSize: '12px',
              marginBottom: '27px',
              maxWidth: '100%',
              [theme.breakpoints.down('tablet')]: {
                textAlign: 'left'
              }
            })}
          >
            {text.information}
          </ClipTypography>
          <InformationContainer>
            <ImagePreview
              variant="square"
              size="big"
              onUpload={handleUpload}
              letters={values.name}
              src={imageSrc}
            />

            <FieldsContainer>
              <AddProductForm>
                <ClipInput
                  required
                  label={text.productName}
                  error={hasError('name', errors, touched)}
                  helperText={hasError('name', errors, touched) && errors.name}
                  type="text"
                  size={validateInputSize()}
                  value={values.name}
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Space />
                <ClipInput
                  error={hasError('description', errors, touched)}
                  helperText={
                    hasError('description', errors, touched) &&
                    errors.description
                  }
                  label={text.description}
                  type="text"
                  multiline
                  rows={3}
                  size={validateInputSize()}
                  value={values.description}
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Space />

                <InputPrice
                  required
                  priceLabel={text.priceText}
                  error={hasError('price', errors, touched)}
                  helperText={
                    hasError('price', errors, touched) && errors.price
                  }
                  value={values.price}
                  size={validateInputSize()}
                  name="price"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={{ inputMode: 'numeric' }}
                  isEdit={isEdit}
                />
              </AddProductForm>
              <ClipTypography
                color="surface.950"
                sx={{ marginBottom: '16px', fontWeight: '600' }}
              >
                {text.categories}
              </ClipTypography>
              <ClipTypography
                color="surface.700"
                sx={{
                  marginBottom: theme.spacing(4),
                  [theme.breakpoints.down('tablet')]: {
                    fontSize: '14px'
                  }
                }}
              >
                {text.categoriesText}
              </ClipTypography>

              {validateAddCategoryComponent()}
              {productCategories.map((productCategory, index) => {
                const isCategoryDisabled =
                  currentCategoryName === productCategory;
                const onDeleteCallback = isCategoryDisabled
                  ? undefined
                  : () => handleOpenDialog(productCategory, index);
                return (
                  <ClipChip
                    sx={{
                      maxWidth: '150px',
                      marginLeft: theme.spacing(1),
                      marginTop: theme.spacing(8),
                      [theme.breakpoints.down('tablet')]: {
                        marginTop: theme.spacing(4)
                      },
                      '&.MuiChip-filled.Mui-disabled': {
                        backgroundColor: theme.palette.surface[700],
                        color: theme.palette.surface[50]
                      }
                    }}
                    key={productCategory}
                    label={productCategory}
                    color="surface"
                    size="small"
                    deleteIcon={<Close />}
                    disabled={isCategoryDisabled}
                    onDelete={onDeleteCallback}
                  />
                );
              })}
            </FieldsContainer>
          </InformationContainer>
        </ClipCardContentStyled>
      </ClipCard>

      {validateDeleteConfirm()}
    </>
  );
};
