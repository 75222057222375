import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card as ClipCard,
  CardContent as ClipCardContent,
  Checkbox as ClipCheckBox,
  theme,
  Typography as ClipTypography
} from '@clipmx/clip-components';
import {
  ProductAdd as ClipProductAdd,
  StoreShoppingList as ClipStoreShoppingList,
  Trash as ClipTrash
} from '@clipmx/clip-icons';
import { productInCategoryFormatter } from 'helpers/utils';
import { urls } from 'helpers/navigation';
import {
  selectProducts,
  selectProductsLoading
} from 'store/products/selectors';
import {
  selectCategoriesError,
  selectCategoriesLoading
} from 'store/categories/selectors';
import { getCategoryByIdThunk } from 'store/categories/thunks';
import { PRODUCT_ATTRIBUTES } from 'views/constants';
import { ConfirmationDialogWithDrawer } from 'components/ConfirmationDialogWithDrawer/ConfirmationDialogWithDrawer';
import { Loader } from 'components/Loader/Loader';
import { CardErrorNetwork } from 'components/CardErrorNetwork/CardErrorNetwork';
import { CardListView } from 'views/CatalogList/CardListView/CardListView';
import { FloatingBottomBar } from 'components/FloatingBottomBar/FloatingBottomBar';
import {
  ClipCardActionAreaStyled,
  ClipCardContentStyled,
  ClipCardProductsCategoryContainer,
  ClipCardTextContainer,
  ClipTypographySubTitle,
  ClipTypographyTitle,
  Divider
} from './styled';
import { text } from './text';

export const CategoryProductOptions = (props) => {
  const {
    categoryId,
    categoryProducts,
    setCategoryProducts,
    setUnsavedChanges,
    navigateTo
  } = props;

  const [tempProducts, setTempProducts] = useState({});
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const allProducts = useSelector(selectProducts);
  const productsLoading = useSelector(selectProductsLoading);
  const categoriesLoading = useSelector(selectCategoriesLoading);
  const categoriesError = useSelector(selectCategoriesError);
  const dispatch = useDispatch();

  const handleDeleteModalOpen = () => {
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const onExistingProductClick = () => {
    setTempProducts({});
    navigateTo(urls.existingProducts);
  };

  const onCreateProductClick = () => {
    setTempProducts({});
    navigateTo(urls.newProduct);
  };

  const handleOnSelect = (item) => {
    const itemId = item?.product_id;
    if (!itemId) {
      return;
    }

    const newSelectedProducts = { ...tempProducts };
    if (!newSelectedProducts[itemId]) {
      newSelectedProducts[itemId] = {
        isSelected: true,
        value: item
      };
    } else {
      delete newSelectedProducts[itemId];
    }

    setTempProducts(newSelectedProducts);
  };

  const handleSelectAll = () => {
    const totalItems = categoryProducts?.length ?? 0;
    const totalSelected = Object.keys(tempProducts).length;
    let allSelectedItems = {};

    if (totalItems > totalSelected) {
      categoryProducts?.map((item) => {
        allSelectedItems[item?.product_id] = {
          value: item,
          isSelected: true
        };
      });
    }

    setTempProducts(allSelectedItems);
  };

  const handleRemoveItemsFromCategory = () => {
    const newCategoryProducts = categoryProducts?.filter((categoryProduct) => {
      if (tempProducts[categoryProduct?.product_id]) {
        return false;
      }

      return true;
    });

    setCategoryProducts(newCategoryProducts);
    setTempProducts({});
    setUnsavedChanges(true);
    handleDeleteModalClose();
  };

  const selectedProductsSize = Object.keys(tempProducts)?.length;
  const indeterminate =
    selectedProductsSize > 0 && selectedProductsSize < categoryProducts?.length;
  const allChecked =
    selectedProductsSize > 0 &&
    selectedProductsSize === categoryProducts?.length;

  const handleOnRefresh = () => {
    if (categoryId) {
      dispatch(getCategoryByIdThunk(categoryId));
    }
  };

  return (
    <>
      <>
        <ClipCard
          bgColorVariant="50"
          borderRadius={2}
          sx={(theme) => ({
            [theme.breakpoints.down('tablet')]: {
              backgroundColor: theme.palette.surface[50],
              boxShadow: 'none',
              marginBottom: selectedProductsSize
                ? theme.spacing(6)
                : theme.spacing(1)
            }
          })}
        >
          <ClipCardContentStyled>
            {categoriesLoading || productsLoading ? (
              <Loader message={text.productsLoading} />
            ) : categoriesError?.found ? (
              <CardErrorNetwork onRefresh={handleOnRefresh} />
            ) : (
              <>
                <ClipTypography
                  sx={(theme) => ({
                    [theme.breakpoints.down('tablet')]: {
                      display: 'none'
                    }
                  })}
                >
                  {text.productOptionsTitle}
                </ClipTypography>
                <ClipTypographyTitle
                  variant={'c1'}
                  sx={(theme) => ({
                    [theme.breakpoints.up('tablet')]: {
                      display: 'none'
                    }
                  })}
                >
                  {`${text.title} (${categoryProducts?.length})`}
                </ClipTypographyTitle>
                <Divider />
                <ClipCardProductsCategoryContainer>
                  <ClipCardActionAreaStyled
                    borderRadius={2}
                    hoverColorVariant="50"
                    hoverColorPalette="surface"
                    onClick={onCreateProductClick}
                  >
                    <ClipCardContent
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <ClipProductAdd
                        sx={(theme) => ({
                          color: theme.palette.primary[600],
                          marginRight: '12px',
                          pointerEvents: 'none'
                        })}
                      />
                      <ClipTypography
                        variant="sm"
                        color="surface.950"
                        sx={{ fontWeight: theme.typography.fontWeightMedium }}
                      >
                        {text.addNewProduct}
                      </ClipTypography>
                    </ClipCardContent>
                  </ClipCardActionAreaStyled>
                  {!!allProducts?.length && (
                    <ClipCardActionAreaStyled
                      borderRadius={2}
                      hoverColorVariant="50"
                      hoverColorPalette="surface"
                      onClick={onExistingProductClick}
                    >
                      <ClipCardContent
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <ClipStoreShoppingList
                          sx={(theme) => ({
                            color: theme.palette.primary[600],
                            marginRight: '12px',
                            pointerEvents: 'none'
                          })}
                        />
                        <ClipTypography
                          variant="sm"
                          color="surface.950"
                          sx={{
                            fontWeight: theme.typography.fontWeightMedium
                          }}
                        >
                          {text.addExistingProduct}
                        </ClipTypography>
                      </ClipCardContent>
                    </ClipCardActionAreaStyled>
                  )}
                </ClipCardProductsCategoryContainer>
                <ClipTypographyTitle
                  variant={'c1'}
                  sx={(theme) => ({
                    [theme.breakpoints.down('tablet')]: {
                      display: 'none'
                    }
                  })}
                >
                  {`${text.title} (${categoryProducts?.length})`}
                </ClipTypographyTitle>
                {categoryProducts?.length ? (
                  <>
                    <ClipCardTextContainer>
                      <ClipTypographySubTitle variant={'sm'}>
                        <ClipCheckBox
                          label={`${selectedProductsSize} ${text.selectedItems}`}
                          indeterminate={indeterminate}
                          checked={allChecked}
                          onChange={handleSelectAll}
                        />
                      </ClipTypographySubTitle>
                    </ClipCardTextContainer>
                  </>
                ) : null}
                <Divider />
                <CardListView
                  items={categoryProducts}
                  attributes={PRODUCT_ATTRIBUTES}
                  selectedItems={tempProducts}
                  onSelectItem={handleOnSelect}
                  secondaryLabelFormatter={productInCategoryFormatter}
                />
                {!!selectedProductsSize && <Divider />}
              </>
            )}
          </ClipCardContentStyled>
        </ClipCard>
        <FloatingBottomBar
          title={text.selectedElements(selectedProductsSize)}
          numberOfItems={selectedProductsSize}
          primaryButton={{
            onClick: handleDeleteModalOpen,
            text: text.removeProducts,
            icon: ClipTrash
          }}
        />
        <ConfirmationDialogWithDrawer
          open={isDeleteModalOpen}
          onClose={handleDeleteModalClose}
          text={{
            title: text.removeProductTitle,
            description: text.removeProductDescription,
            primaryLabel: text.removeProductAccept,
            secondaryLabel: text.removeProductCancel
          }}
          handlePrimaryAction={handleRemoveItemsFromCategory}
          handleSecondaryAction={handleDeleteModalClose}
        />
      </>
    </>
  );
};
