import {
  theme,
  IconButton as ClipIconButton,
  Input as ClipInput,
  List as ClipList,
  ListItemButton as ClipListItemButton,
  ListItemText as ClipListItemText
} from '@clipmx/clip-components';
import { Divider } from 'components/Divider/Divider';
import { ArrowRight, PlusAdd } from '@clipmx/clip-icons';
import { hasError } from 'helpers/fieldValidations';
import {
  ClipTypographyTitleSecondDialog,
  TitleContent,
  DialogContent,
  HeaderDialog,
  ClipTypographyMessage
} from './styled';

export const AttributesDialogContent = (props) => {
  const {
    backToInitialDialog,
    title,
    message,
    label,
    handleChangeOptionName,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleListItemClick,
    availableOptions = []
  } = props;

  const handleOptionUsers = () => {
    handleListItemClick('');
    !errors.attribute && handleChangeOptionName(values.attribute);
  };
  const handleOptioUsersKeyDown = (ev) => {
    ev.key === 'Enter' && handleOptionUsers();
  };

  return (
    <>
      <HeaderDialog>
        <ClipIconButton color="transparent" onClick={backToInitialDialog}>
          <ArrowRight
            sx={{
              pointerEvents: 'none',
              transform: 'rotate(180deg)'
            }}
          />
        </ClipIconButton>
        <TitleContent>
          <ClipTypographyTitleSecondDialog variant="body1">
            {title}
          </ClipTypographyTitleSecondDialog>
        </TitleContent>
      </HeaderDialog>
      <Divider
        sx={(theme) => ({
          marginBottom: theme.spacing(6),
          borderColor: theme.palette.surface[200],
          marginLeft: theme.spacing(-8),
          marginRight: theme.spacing(-8),
          [theme.breakpoints.down('tablet')]: {
            marginLeft: `-${theme.spacing(4)}`,
            marginRight: `-${theme.spacing(4)}`
          }
        })}
        light
      />
      <ClipTypographyMessage
        sx={(theme) => ({
          marginBottom: `${theme.spacing(8)} `,
          [theme.breakpoints.down('tablet')]: {
            marginBottom: `${theme.spacing(4)}`
          }
        })}
      >
        {message}
      </ClipTypographyMessage>
      <DialogContent>
        <>
          <ClipInput
            name="attribute"
            label={label}
            value={values.attribute}
            onChange={handleChange}
            onBlur={handleBlur}
            error={hasError('attribute', errors, touched)}
            helperText={
              hasError('attribute', errors, touched) && errors.attribute
            }
            onKeyDown={(ev) => handleOptioUsersKeyDown(ev)}
          />
          <ClipIconButton
            color="primary"
            sx={{ marginLeft: theme.spacing(2), marginTop: theme.spacing(2) }}
            onClick={() => handleOptionUsers()}
          >
            <PlusAdd />
          </ClipIconButton>
        </>
      </DialogContent>
      {availableOptions?.map((optionItem) => (
        <ClipList key={optionItem} sx={{ padding: theme.spacing(0) }}>
          <ClipListItemButton
            sx={(theme) => ({
              padding: `${theme.spacing(5)} ${theme.spacing(6)}`,
              marginX: `-${theme.spacing(8)}`,
              [theme.breakpoints.down('tablet')]: {
                marginX: `-${theme.spacing(4)}`
              }
            })}
            onClick={() => handleListItemClick(optionItem)}
          >
            <ClipListItemText
              sx={{ paddingLeft: theme.spacing(3) }}
              primaryTypographyProps={{
                color: `${theme.palette.surface[700]} !important`
              }}
              primary={optionItem}
            />
          </ClipListItemButton>
        </ClipList>
      ))}
    </>
  );
};
