import { PlusAdd, EditPen } from '@clipmx/clip-icons';
import { useEffect, useRef, useState } from 'react';
import { ThumbnailLetter } from 'components/ThumbnailLetter/ThumbnailLetter';
import { validateImageSize } from 'helpers/utils';
import { useDispatch } from 'react-redux';
import { fireNotification } from 'store/notifications/thunks';
import { SEVERITY } from 'components/Notification/Notification';
import {
  ImagePreviewContainer,
  ThumbnailImage,
  Uploader,
  ClipIconButtonStyled
} from './styled';
import { text } from './text';

export const ImagePreview = (props) => {
  const { src, alt, letters, onUpload, ...rest } = props;
  const [image, setImage] = useState({
    preview: '',
    alt: ''
  });
  const [edit, setEdit] = useState(false);
  const uploader = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    src && setEdit(true);
  }, []);

  const handleUpload = () => {
    uploader.current && uploader.current.click();
  };

  const handleChangeUploader = (ev) => {
    ev.preventDefault();
    const imgFile = ev.target.files[0];

    if (validateImageSize(imgFile.size)) {
      imgFile && setEdit(true);
      const reader = new FileReader();
      reader.readAsDataURL(imgFile);
      setImage({
        preview: URL.createObjectURL(imgFile),
        alt: imgFile.name
      });
      reader.onloadend = () => {
        onUpload({ file: imgFile, base64: reader.result });
      };
    } else {
      // clear image input
      uploader.current.value = '';
      dispatch(fireNotification(SEVERITY.error, text.invalidSize));
    }
  };

  return (
    <ImagePreviewContainer>
      {letters && !image.preview && !src ? (
        <ThumbnailLetter text={letters} />
      ) : (
        <ThumbnailImage
          src={image?.preview || src}
          alt={image?.alt || alt}
          {...rest}
        />
      )}
      <Uploader
        type="file"
        accept="image/*"
        ref={uploader}
        onChange={handleChangeUploader}
      />
      <ClipIconButtonStyled
        color="primary"
        variant="circular"
        onClick={handleUpload}
      >
        {!edit ? (
          <PlusAdd sx={{ pointerEvents: 'none' }} />
        ) : (
          <EditPen sx={{ pointerEvents: 'none' }} />
        )}
      </ClipIconButtonStyled>
    </ImagePreviewContainer>
  );
};
