export const mask = (ev) => {
  let value = ev.target.value;
  const precision = Number(2);
  const decimalSeparator = '.';
  const thousandSeparator = ',';

  if (value === null || value === undefined) {
    return {
      value: 0,
      maskedValue: '0.00'
    };
  }

  //if the given value is a Number, let's convert into String to manipulate that
  value = String(value);

  if (value.length === 0 || value.length > 30) {
    return {
      value: 0,
      maskedValue: '0.00'
    };
  }

  // extract digits. if no digits, fill in a zero.
  let digits = value.match(/\d/g) || ['0'];

  // zero-pad a input
  while (digits.length <= precision) {
    digits.unshift('0');
  }

  if (precision > 0) {
    // add the decimal separator
    digits.splice(digits.length - precision, 0, '.');
  }

  // clean up extraneous digits like leading zeros.
  digits = Number(digits.join('')).toFixed(precision).split('');
  let raw = Number(digits.join(''));

  // -1 needed to position the decimal separator before the digits.
  let decimalPosition = digits.length - precision - 1;
  if (precision > 0) {
    // set the final decimal separator
    digits[decimalPosition] = decimalSeparator;
  } else {
    // when precision is 0, there is no decimal separator.
    decimalPosition = digits.length;
  }

  // add in any thousand separators
  for (let x = decimalPosition - 3; x > 0; x = x - 3) {
    digits.splice(x, 0, thousandSeparator);
  }

  return {
    value: raw,
    maskedValue: digits.join('').trim()
  };
};

export const getInitialFormattedPrice = (rawPrice) => {
  let formattedPrice = '0.00';

  if (!isFinite(rawPrice)) {
    return formattedPrice;
  }

  formattedPrice = Number(rawPrice).toLocaleString('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return formattedPrice;
};
