import {
  theme,
  Button as ClipButton,
  Dialog as ClipDialog
} from '@clipmx/clip-components';
import { SwipeableDrawer } from 'components/SwipeableDrawer/SwipeableDrawer';
import { useScreenSize } from 'helpers/hooks/useScreenSize';
import {
  SwipeableTitle,
  SwipeableDescription,
  SwipeableActionContainer,
  ConfirmContainer
} from './styled';

export const ConfirmationDialogWithDrawer = (props) => {
  const {
    text,
    open,
    onOpen = () => null,
    onClose = () => null,
    handlePrimaryAction = () => null,
    handleSecondaryAction = () => null,
    transitionDuration = 450
  } = props;
  const width = useScreenSize();
  const isMobile = width <= theme.breakpoints.values.tablet;

  return isMobile ? (
    <SwipeableDrawer open={open} onClose={onClose} onOpen={onOpen}>
      <ConfirmContainer>
        <SwipeableTitle>{text?.title}</SwipeableTitle>
        <SwipeableDescription>{text?.description}</SwipeableDescription>
        <SwipeableActionContainer>
          <ClipButton
            aria-label="button-secondary"
            variant="contained"
            size="small"
            sx={{ flexGrow: 1, mr: 1 }}
            color="tertiary"
            onClick={handleSecondaryAction}
          >
            {text?.secondaryLabelMobile ?? text?.secondaryLabel}
          </ClipButton>
          <ClipButton
            variant="contained"
            aria-label="button-primary"
            size="small"
            sx={{ flexGrow: 1, ml: 1 }}
            color="primary"
            onClick={handlePrimaryAction}
          >
            {text?.primaryLabelMobile ?? text?.primaryLabel}
          </ClipButton>
        </SwipeableActionContainer>
      </ConfirmContainer>
    </SwipeableDrawer>
  ) : (
    <ClipDialog
      transitionDuration={transitionDuration}
      open={open}
      onClose={onClose}
      title={text?.title}
      description={text?.description}
      primaryButtonLabel={text?.primaryLabel}
      primaryButtonAction={handlePrimaryAction}
      secondaryButtonLabel={text?.secondaryLabel}
      secondaryButtonAction={handleSecondaryAction}
    />
  );
};
