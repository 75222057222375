import styled from '@emotion/styled';
import { theme, Card } from '@clipmx/clip-components';
import { Refresh } from '@clipmx/clip-illustrations';

export const ClipCard = styled(Card)`
  padding: 24px;
  background-color: ${theme.palette.surface[50]};
  box-shadow: none;
`;

export const CardErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
`;

export const RefreshIllustration = styled(Refresh)`
  width: 210px;
  height: 210px;
  margin-top: 67px;
  margin-left: 40px;
  ${theme.breakpoints.down('tablet')} {
    background-color: ${theme.palette.surface[50]};
    margin-top: -11px;
  }
`;
