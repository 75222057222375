import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    items: [],
    loading: false,
    error: {
      found: false,
      message: '',
      code: 418 // i'm a teapot
    },
    selectedProduct: {
      error: {
        found: false,
        message: '',
        code: 418
      },
      loading: false,
      value: null
    }
  },
  reducers
});

const { actions, reducer } = productsSlice;

export const {
  requestProducts,
  responseProducts,
  errorProducts,
  getAllProducts,
  requestSelectedProduct,
  responseSelectedProduct,
  setSelectedProduct,
  setSelectedProductError
} = actions;
export default reducer;
