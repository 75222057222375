import { apiClient } from 'services';

const PRODUCTS_ENDPOINT = '/products';

const addNew = (product) => {
  return apiClient
    .post(PRODUCTS_ENDPOINT, product)
    .then((response) => response.data)
    .catch(() => {
      throw {
        found: true,
        message: 'has ocurred an error in addNewProduct',
        code: 500
      };
    });
};

const getAll = () => {
  return apiClient
    .get(PRODUCTS_ENDPOINT)
    .then((response) => response.data)
    .catch(() => {
      throw {
        found: true,
        message: 'has ocurred an error in getAllProducts',
        code: 500
      };
    });
};

export const getById = (producId) =>
  apiClient
    .get(`${PRODUCTS_ENDPOINT}/${producId}`)
    .then((response) => response.data)
    .catch(() => {
      throw {
        found: true,
        message: 'has ocurred an error in getProductById',
        code: 500
      };
    });

const updateById = (product) =>
  apiClient
    .patch(`${PRODUCTS_ENDPOINT}/${product.product_id}`, product)
    .then((response) => response.data)
    .catch(() => {
      throw {
        found: true,
        message: 'has occured an error in updateProductById',
        code: 500
      };
    });

export default {
  addNew,
  getAll,
  getById,
  updateById
};
