import './App.css';
import { Routes, Route } from 'react-router-dom';
import { CatalogList } from 'views/CatalogList/CatalogList';
import { NewCategory } from 'views/NewCategory/NewCategory';
import { EditCategory } from 'views/EditCategory/EditCategory';
import { urls } from 'helpers/navigation';
import { NewProduct } from 'views/NewProduct/NewProduct';
import { VerifyInformation } from 'helpers/components/VerifyInformation';
import ScrollToTop from 'helpers/components/ScrollToTop';
import { EmptyState } from './views/EmptyState/EmptyState';
import { ExistingProducts } from './views/ExistingProducts/ExistingProducts';
import { EditProduct } from './views/EditProduct/EditProduct';

function App() {
  return (
    <div className="App">
      <ScrollToTop>
        <Routes>
          <Route
            path={urls.catalogEmpty}
            element={
              <VerifyInformation>
                <EmptyState />
              </VerifyInformation>
            }
          />
          <Route path={urls.catalogList} element={<CatalogList />} />
          <Route path={urls.newCategory} element={<NewCategory />} />
          <Route path={urls.newProduct} element={<NewProduct />} />
          <Route path={urls.editCategory} element={<EditCategory />} />
          <Route path={urls.editProduct} element={<EditProduct />} />
          <Route path={urls.existingProducts} element={<ExistingProducts />} />
        </Routes>
      </ScrollToTop>
    </div>
  );
}

export default App;
