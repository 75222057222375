export const text = {
  productName: 'Nombre del producto',
  information: 'INFORMACION DEL PRODUCTO',
  description: 'Descripción',
  priceText: 'Precio al público',
  categories: 'Categorías asignadas',
  categoriesText: 'Podrás asignarle como máximo dos categorías a tu producto',
  assingCategory: 'Asignar nueva',
  addACategory: 'Agregar una categoría',
  addNewCategory: 'Agregar nueva categoría',
  price: '$ 0.00',
  dialog: {
    title: '¿Quieres quitar la categoría de este producto?',
    description:
      'El producto será removido de esta categoría, y podrás encontrarlo únicamente en la sección de productos o dentro de la otra categoría que tengas asignada.',
    primaryLabel: 'Sí, quitar',
    secondaryLabel: 'No, regresar',
    secondaryLabelMobile: 'No, cancelar'
  }
};
