import { parseBase64Image } from 'helpers/utils';
import { apiClient } from 'services';

const IMAGES_ENDPOINT = '/images';

const uploadImage = (image) => {
  const base64Plain = parseBase64Image(image);
  return apiClient
    .post(IMAGES_ENDPOINT, base64Plain, {
      headers: {
        'Content-Type': 'text/plain'
      }
    })
    .then((response) => response.data)
    .catch(() => {
      throw {
        found: true,
        message: 'has ocurred an error in uploadImage',
        code: 500
      };
    });
};

export default {
  uploadImage
};
