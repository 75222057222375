import { Card as ClipCard, Input as ClipInput } from '@clipmx/clip-components';
import { ImagePreview } from 'components/ImagePreview/ImagePreview';
import { Loader } from 'components/Loader/Loader';
import { hasError } from 'helpers/fieldValidations';
import { ClipCardContentStyled, FieldsContainer, Divider } from './styled';
import { text } from './text';

export const AddCategory = (props) => {
  const {
    values,
    handleChange,
    handleBlur,
    handleUpload,
    errors,
    touched,
    imageSrc,
    isEditMode,
    loading
  } = props;

  return (
    <ClipCard bgColorVariant="50" borderRadius={2}>
      <ClipCardContentStyled>
        {loading && isEditMode ? (
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <Loader message={text.categoryLoading} />
          </div>
        ) : (
          <>
            <ImagePreview
              variant="square"
              size="big"
              onUpload={handleUpload}
              letters={values.name}
              src={imageSrc}
            />
            <FieldsContainer>
              <ClipInput
                required
                error={hasError('name', errors, touched)}
                helperText={hasError('name', errors, touched) && errors.name}
                name="name"
                label={text.categoryName}
                type="text"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Divider />
              <ClipInput
                error={hasError('description', errors, touched)}
                helperText={
                  hasError('description', errors, touched) && errors.description
                }
                name="description"
                multiline
                rows={8}
                label={text.description}
                type="text"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FieldsContainer>
          </>
        )}
      </ClipCardContentStyled>
    </ClipCard>
  );
};
