// image helpers
export const parseBase64Image = (image) =>
  image.replace(/^data:image\/[a-z]+;base64,/, '');

export const validateImageSize = (imgSize) => {
  const MAX_SIZE = 2048;
  const kb = 1024;
  const imageKbSize = imgSize / kb;
  return imageKbSize <= MAX_SIZE;
};

export const generateThumbanilLetterFromName = (name) =>
  name && name[0].toUpperCase();

// text formatting helpers
export const defaultSecondaryLabelFormatter = (item, attributes) =>
  `${item?.[attributes.children.id]?.length} ${attributes.children.label}`;

export const productInCategoryFormatter = (item) =>
  typeof item?.stock === 'number' && `${item.stock} en existencia`;

//Default options add attribute
export const defaultOptions = {
  Tamaño: ['Chico', 'Mediano', 'Grande'],
  Color: ['Naranja', 'Amarillo', 'Azul', 'Negro', 'Rojo']
};

export const getInitialNumberValue = (value) =>
  isFinite(value) ? Number(value) : 0;

export const handleSelectItem = (item, itemKey, allItems) => {
  const itemId = item?.[itemKey];

  if (!itemId) {
    return allItems;
  }

  const newSelectedItems = { ...allItems };
  if (!newSelectedItems[itemId]) {
    newSelectedItems[itemId] = {
      isSelected: true,
      value: item
    };
  } else {
    delete newSelectedItems[itemId];
  }

  return newSelectedItems;
};

export const generateCombinationsFromStringArray = (strArray) => {
  const strArrayLen = strArray.length - 1;

  return strArray.reduce(
    (previousValue, currentValue, currentIndex) =>
      previousValue.flatMap((prev) =>
        currentValue.map(
          (curr) => `${prev} ${curr} ${currentIndex < strArrayLen ? '/' : ''}`
        )
      ),
    ['']
  );
};
