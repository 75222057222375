import categoriesService from 'services/categories';
import { getAllProductsThunk } from 'store/products/thunks';
import {
  errorCategories,
  getAllCategories,
  requestCategories,
  responseCategories,
  setSelectedItem
} from './categoriesSlice';

export const getAllCategoriesThunk = () => (dispatch) => {
  dispatch(errorCategories({ found: false, message: '', code: 418 }));
  dispatch(requestCategories());

  categoriesService
    .getAll()
    .then((res) => {
      dispatch(getAllCategories(res.data));
    })
    .catch((err) => {
      dispatch(errorCategories(err));
    })
    .finally(() => {
      dispatch(responseCategories());
    });
};

export const addNewCategoryThunk =
  (category, onSuccess, onFailure) => (dispatch, getState) => {
    dispatch(requestCategories());

    return categoriesService
      .addNew(category)
      .then((res) => {
        const newCategory = res.data;
        const currentCategories = getState().categories.items;
        dispatch(getAllCategories([...currentCategories, newCategory]));
        onSuccess?.(newCategory);
      })
      .catch((err) => {
        dispatch(errorCategories(err));
        onFailure?.();
      })
      .finally(() => {
        dispatch(responseCategories());
      });
  };

export const getCategoryByIdThunk =
  (categoryId, onSuccess, onFailure) => (dispatch) => {
    dispatch(requestCategories());

    const categoryRequests = [
      categoriesService.getById(categoryId),
      categoriesService.getProductsByCategoryId(categoryId)
    ];

    Promise.all(categoryRequests)
      .then((res) => {
        const selectedCategory = res[0].data || {};
        selectedCategory.products = res[1].data;

        dispatch(setSelectedItem(selectedCategory));
        onSuccess?.();
      })
      .catch((err) => {
        dispatch(errorCategories(err));
        onFailure?.();
      })
      .finally(() => {
        dispatch(responseCategories());
      });
  };

export const updateCategoryThunk =
  (category, onSuccess, onFailure) => (dispatch, getState) => {
    dispatch(requestCategories());

    categoriesService
      .updateById(category)
      .then((res) => {
        const updatedCategory = res.data;
        const currentCategories = [...getState().categories.items];
        const updatedCategoryPosition = currentCategories.findIndex(
          (item) => item?.category_id === category.category_id
        );

        if (updatedCategoryPosition !== -1) {
          currentCategories.splice(updatedCategoryPosition, 1);
        }

        dispatch(getAllCategories([...currentCategories, updatedCategory]));
        onSuccess?.();
      })
      .catch((err) => {
        dispatch(errorCategories(err));
        onFailure?.();
      })
      .finally(() => {
        dispatch(responseCategories);
      });
  };

export const deleteCategoriesByIdThunk =
  (categories, onSuccess, onFailure) => (dispatch) => {
    dispatch(requestCategories());

    const categoriesToDeletePromise = categories.map((categoryId) =>
      categoriesService.deleteById(categoryId)
    );

    Promise.allSettled(categoriesToDeletePromise).then((results) => {
      const promiseResult = results.reduce(
        (prevResults, currentResult) => {
          currentResult.status === 'fulfilled' && prevResults.success++;
          currentResult.status === 'rejected' && prevResults.error++;

          return prevResults;
        },
        {
          success: 0,
          error: 0
        }
      );

      dispatch(getAllCategoriesThunk());
      dispatch(getAllProductsThunk());
      promiseResult.success > 0 && onSuccess?.(promiseResult.success);
      promiseResult.error > 0 && onFailure?.(promiseResult.error);
    });
  };
