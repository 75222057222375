import {
  theme,
  List as ClipList,
  ListItem as ClipListItem,
  ListItemText as ClipListItemText,
  Checkbox as ClipCheckBox,
  IconButton as ClipIconButton
} from '@clipmx/clip-components';
import { EditPen, Trash } from '@clipmx/clip-icons';
import { Accordion } from 'components/Accordion/Accordion';
import { generateCombinationsFromStringArray } from 'helpers/utils';
import { ClipTypographySubtitle } from './styled';
import { text } from './text';

export const Variants = (props) => {
  const { productOptions } = props;

  const combineVariants = () => {
    const variants = productOptions.map((el) => el.variants);
    return generateCombinationsFromStringArray(variants);
  };

  return (
    <Accordion title={text.title} defaultOpen>
      <ClipTypographySubtitle>{text.subtitle}</ClipTypographySubtitle>
      {combineVariants().map((variant) => (
        <ClipList key={variant}>
          <ClipListItem disableGutters divider>
            <ClipCheckBox />
            <ClipListItemText size="small">{variant}</ClipListItemText>
            <ClipListItemText
              primary="$32.00"
              primaryTypographyProps={{
                textAlign: 'end'
              }}
              size="small"
            />
            <ClipIconButton
              sx={{ margin: `0 ${theme.spacing(2)} 0 ${theme.spacing(4)}` }}
              size="large"
              variant="circular"
              color="transparent"
            >
              <EditPen />
            </ClipIconButton>
            <ClipIconButton size="large" variant="circular" color="transparent">
              <Trash />
            </ClipIconButton>
          </ClipListItem>
        </ClipList>
      ))}
    </Accordion>
  );
};
