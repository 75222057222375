import {
  theme,
  CircularProgress as ClipCircularProgress
} from '@clipmx/clip-components';
import { useNavigate } from 'react-router-dom';
import { ArrowRight } from '@clipmx/clip-icons';
import { useScreenSize } from 'helpers/hooks/useScreenSize';
import {
  HeaderContainer,
  NavigationContainer,
  ClipTypographyTitle,
  ClipIconButtonStyled,
  ClipButtonStyled
} from './styled';

export const Header = (props) => {
  const {
    title,
    actionBackArrow,
    hideBackArrow = false,
    textButton = '',
    actionButton,
    loadingButton,
    hideActionButton
  } = props;
  const width = useScreenSize();
  const navigate = useNavigate();
  const actionBackNavigation = () => {
    navigate(-1);
  };

  const handleVariant = () => {
    return width > theme.breakpoints.values.tablet ? 'contained' : 'text';
  };

  const handleTextButton = () => {
    return width > theme.breakpoints.values.tablet
      ? textButton
      : textButton.split(' ')[0];
  };

  return (
    <HeaderContainer>
      <NavigationContainer>
        {!hideBackArrow && (
          <ClipIconButtonStyled
            color="transparent"
            onClick={actionBackArrow ?? actionBackNavigation}
          >
            <ArrowRight
              sx={{
                pointerEvents: 'none',
                transform: 'rotate(180deg)'
              }}
            />
          </ClipIconButtonStyled>
        )}
        <ClipTypographyTitle>{title}</ClipTypographyTitle>
      </NavigationContainer>
      {textButton && (
        <ClipButtonStyled
          onClick={!loadingButton ? actionButton : undefined}
          variant={handleVariant()}
          size="large"
          sx={{
            [theme.breakpoints.down('tablet')]: hideActionButton && {
              display: 'none'
            }
          }}
        >
          {loadingButton ? (
            <ClipCircularProgress size={30} />
          ) : (
            handleTextButton()
          )}
        </ClipButtonStyled>
      )}
    </HeaderContainer>
  );
};
