import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    items: [],
    loading: false,
    error: {
      found: false,
      message: '',
      code: 418 // i'm a teapot
    },
    selectedItem: null
  },
  reducers
});

const { actions, reducer } = categoriesSlice;

export const {
  requestCategories,
  responseCategories,
  errorCategories,
  getAllCategories,
  setSelectedItem
} = actions;
export default reducer;
