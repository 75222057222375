import styled from '@emotion/styled';
import {
  theme,
  CardContent as ClipCardContent,
  Typography as ClipTypography
} from '@clipmx/clip-components';

export const ClipCardContentStyled = styled(ClipCardContent)`
  ${theme.breakpoints.down('tablet')} {
    padding-left: 0;
    padding-right: 0;
    background-color: ${theme.palette.surface[50]};
  }
`;

export const ClipTypographySubheader = styled(ClipTypography)`
  ${theme.typography.body1};
  color: ${theme.palette.surface[950]};
  margin-bottom: 32px;

  ${theme.breakpoints.down('tablet')} {
    ${theme.typography.sm}
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const Divider = styled.div`
  ${theme.breakpoints.down('tablet')} {
    height: 32px;
    width: 100%;
    background-color: ${theme.palette.surface[200]};
  }
`;
