export const requestImages = (state) => {
  state.loading = true;
};

export const responseImages = (state) => {
  state.loading = false;
};

export const errorImages = (state, action) => {
  state.error = action.payload;
};

export default {
  requestImages,
  responseImages,
  errorImages
};
