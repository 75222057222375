import { theme, Typography as ClipTypography } from '@clipmx/clip-components';
import styled from '@emotion/styled';
import { importantCSS } from 'helpers/overwriteCSS';

export const ClipTypographyAddLabel = styled(ClipTypography)`
  ${importantCSS({
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightMedium
  })};

  ${theme.breakpoints.down('tablet')} {
    ${importantCSS(theme.typography.sm)}
  }
`;

export const LoaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px 0;
`;
