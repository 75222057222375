import styled from '@emotion/styled';
import { theme, CardContent as ClipCardContent } from '@clipmx/clip-components';

export const ClipCardContentStyled = styled(ClipCardContent)`
  display: flex;
  flex-direction: row;

  ${theme.breakpoints.down('tablet')} {
    flex-direction: column;
    align-items: center;
  }
`;

export const FieldsContainer = styled.div`
  width: 100%;
  margin-left: 16px;

  ${theme.breakpoints.down('tablet')} {
    margin-left: 0;
    margin-top: 16px;
  }
`;

export const Divider = styled.div`
  height: 24px;

  ${theme.breakpoints.down('tablet')} {
    height: 16px;
  }
`;
