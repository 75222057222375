import { useDispatch, useSelector } from 'react-redux';
import { setSelectedItem } from 'store/categories/categoriesSlice';
import { selectCategory } from 'store/categories/selectors';
import { ExistingProductsContent } from 'components/ExistingProductsContent/ExistingProductsContent';

export const ExistingProducts = () => {
  const dispatch = useDispatch();
  const currentCategory = useSelector(selectCategory);
  const categoryProducts = currentCategory?.products ?? [];

  const handleSetCategoryProducts = (newCategoryProducts) => {
    const categoryData = {
      ...currentCategory,
      products: newCategoryProducts,
      isEdited: true
    };
    dispatch(setSelectedItem(categoryData));
  };

  return (
    <ExistingProductsContent
      categoryProducts={categoryProducts}
      setCategoryProducts={handleSetCategoryProducts}
    />
  );
};
