import styled from '@emotion/styled';
import {
  theme,
  Typography as ClipTypography,
  Button as ClipButton,
  IconButton as ClipIconButton
} from '@clipmx/clip-components';
import { importantCSS } from 'helpers/overwriteCSS';

export const ClipTypographyTitleDialog = styled(ClipTypography)`
  ${importantCSS({
    ...theme.typography.xl,
    fontWeight: theme.typography.fontWeightMedium
  })}
  ${theme.breakpoints.down('tablet')} 
    ${importantCSS({
    ...theme.typography.lg
  })}
  color: ${theme.palette.surface[950]};
`;

export const ClipTypographyDescription = styled(ClipTypography)`
  color: ${theme.palette.surface[950]};
  margin-top: 32px;
  margin-bottom: 32px;
  ${theme.breakpoints.down('tablet')} {
    ${importantCSS({
      ...theme.typography.sm,
      color: theme.palette.surface[700]
    })}
    margin-top: 8px;
    margin-bottom: 24px;
  }
`;

export const ClipTypographyDetails = styled(ClipTypography)`
  ${importantCSS({
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.surface[950]
  })}
  ${theme.breakpoints.down('tablet')} {
    margin-bottom: 24px;
  }
  margin-bottom: 16px;
`;

export const ClipTypographyVariants = styled(ClipTypography)`
  ${importantCSS({
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.surface[950]
  })}
  ${theme.breakpoints.down('tablet')} {
    margin-top: 50px;
    margin-bottom: 24px;
  }
  margin-top: 32px;
  margin-bottom: 16px;
`;

export const ActionZone = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const ClipSaveButton = styled(ClipButton)`
  ${theme.breakpoints.down('tablet')} {
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;

export const ClipTypographyLabel = styled(ClipTypography)`
  ${importantCSS({
    fontWeight: theme.typography.fontWeightMedium
  })}
`;

export const ClipButtonNextDialog = styled(ClipIconButton)`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  border-width: 1px;
  border-style: solid;
  padding-left: 16px;
  padding-right: 25px;
`;

export const ClipTypographyHelperText = styled(ClipTypography)`
  ${importantCSS({
    ...theme.typography.sm,
    fontWeight: theme.typography.fontWeightMedium
  })}
`;

export const SpaceButton = styled.div`
  width: 16px;
`;

export const Space = styled.div`
  height: 24px;
`;
export const ChangeStateButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
// Styles secondDialog

export const HeaderDialog = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  margin-top: -${theme.spacing(6)};
`;

export const DialogContent = styled.div`
  display: flex;
  margin-bottom: ${theme.spacing(3)};
`;

export const TitleContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ClipTypographyTitleSecondDialog = styled(ClipTypography)`
  font-weight: ${theme.typography.fontWeightMedium};
  margin-right: 5%;
  color: ${theme.palette.surface[950]};
`;

export const DefaultOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 32px;
`;

export const ClipTypographyLabelOption = styled(ClipTypography)`
  ${importantCSS({
    ...theme.typography.sm,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.surface[700]
  })}
`;

export const ClipTypographyOption = styled(ClipTypography)`
  ${importantCSS({
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.surface[950]
  })}
`;
export const ClipTypographyMessage = styled(ClipTypography)`
  ${importantCSS({
    color: theme.palette.surface[950]
  })}
  ${theme.breakpoints.down('tablet')} {
    ${importantCSS({
      color: theme.palette.surface[700]
    })}
  }
`;
export const SwipeableContent = styled.div`
  padding: ${theme.spacing(4)};
`;
