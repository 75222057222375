import { useState } from 'react';
import {
  theme,
  Accordion as ClipAccordion,
  AccordionSummary as ClipAccordionSummary,
  AccordionDetails as ClipAccordionDetails
} from '@clipmx/clip-components';
import { PlusAdd, ArrowChevronDown } from '@clipmx/clip-icons';
import { CardErrorNetwork } from 'components/CardErrorNetwork/CardErrorNetwork';
import { Loader } from 'components/Loader/Loader';
import {
  ClipTypographyTitle,
  ClipTypographySubtitle,
  CardViewContainerAdd,
  ClipTypographyAddLabel,
  ClipIconButtonAddNew
} from './styled';
import { text } from './text';

export const CardViewContainer = (props) => {
  const {
    children,
    title,
    subtitle,
    addLabel,
    count = 0,
    onNavigate,
    loading,
    canCreate = true,
    handleRefresh,
    networkIssue = false
  } = props;
  const [expanded, setExpanded] = useState(true);

  const handleChange = (_event, newExpanded) => {
    setExpanded(newExpanded);
  };

  return (
    <ClipAccordion
      expanded={expanded}
      onChange={handleChange}
      sx={(theme) => ({
        '&:before': {
          backgroundColor: theme.palette.surface[50]
        },
        marginTop: '16px',
        [theme.breakpoints.down('tablet')]: {
          marginTop: '24px',
          backgroundColor: 'transparent'
        }
      })}
    >
      <ClipAccordionSummary
        expandIcon={<ArrowChevronDown />}
        aria-controls="panel-content"
        id="panel-header"
      >
        <ClipTypographyTitle>
          {title} ({count})
        </ClipTypographyTitle>
      </ClipAccordionSummary>
      <ClipAccordionDetails
        sx={{
          backgroundColor: 'surface.50',
          flexDirection: 'column'
        }}
      >
        <ClipTypographySubtitle>{subtitle}</ClipTypographySubtitle>

        {loading ? (
          <Loader message={`${text.loading} ${title.toLowerCase()}`} />
        ) : (
          <>
            {children}
            {canCreate && (
              <CardViewContainerAdd>
                <ClipIconButtonAddNew
                  color="transparent"
                  variant="rounded"
                  onClick={onNavigate}
                >
                  <ClipTypographyAddLabel color="surface.950">
                    {addLabel}
                  </ClipTypographyAddLabel>
                  <PlusAdd
                    sx={{
                      color: theme.palette.primary['600'],
                      pointerEvents: 'none'
                    }}
                  />
                </ClipIconButtonAddNew>
              </CardViewContainerAdd>
            )}
          </>
        )}
        {networkIssue && <CardErrorNetwork onRefresh={handleRefresh} />}
      </ClipAccordionDetails>
    </ClipAccordion>
  );
};
