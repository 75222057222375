import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProductsThunk } from 'store/products/thunks';
import { useState } from 'react';
import { CategoryContent } from 'components/CategoryContent/CategoryContent';
import { uploadImageThunk } from 'store/images/thunks';
import { selectCategory } from 'store/categories/selectors';
import { setSelectedItem } from 'store/categories/categoriesSlice';
import { addNewCategoryThunk } from 'store/categories/thunks';
import { fireNotification } from 'store/notifications/thunks';
import { SEVERITY } from 'components/Notification/Notification';
import { urls } from 'helpers/navigation';
import { useNavigate } from 'react-router-dom';
import { text } from './text';

export const NewCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const currentCategory = useSelector(selectCategory);

  useEffect(() => {
    dispatch(getAllProductsThunk());
  }, []);

  useEffect(() => {
    if (currentCategory && currentCategory?.products) {
      setCategoryProducts([...currentCategory.products]);
    }
  }, [currentCategory]);

  const handleCreateCategory = async (categoryData, image) => {
    if (image) {
      const thunkImageResult = await dispatch(uploadImageThunk(image));
      categoryData.image = thunkImageResult?.payload;
    }

    setUnsavedChanges(false);

    dispatch(
      addNewCategoryThunk(
        categoryData,
        () => {
          dispatch(fireNotification(SEVERITY.success, text.categoryCreated));
          dispatch(setSelectedItem(null));
          navigate(urls.catalogList);
        },
        () => {
          dispatch(fireNotification(SEVERITY.error, text.categoryError));
          dispatch(setSelectedItem(null));
          navigate(urls.catalogList);
        }
      )
    );
  };

  return (
    <CategoryContent
      currentCategory={currentCategory}
      productList={{ categoryProducts, setCategoryProducts }}
      changes={{ unsavedChanges, setUnsavedChanges }}
      handleCategoryAction={handleCreateCategory}
    />
  );
};
