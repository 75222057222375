import React from 'react';
import { useAutocomplete } from '@mui/material';
import {
  InputContainer,
  ListBox,
  ListItem,
  EndAdornmentContainer
} from './styled';

export const Autocomplete = (props) => {
  const { children, options, onSelectAction, selectedOptions } = props;
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions
  } = useAutocomplete({
    options: options?.data,
    getOptionLabel: (option) => option[options?.optionLabel]
  });

  const newInputProps = {
    inputProps: {
      ...getInputProps(),
      value: children.props.value,
      onChange: (ev) => {
        getInputProps().onChange(ev);
        children.props.onChange(ev);
      },
      onMouseDown: (ev) => {
        !children.props.disabled && getInputProps().onMouseDown(ev);
      },
      onBlur: (ev) => {
        getInputProps().onBlur(ev);
        children.props.onBlur(ev);
      }
    },
    // remove endAdornment prop,
    // it should have an empty jsx element because of error validation icon
    endAdornment: <></>
  };

  const handleClickSelection = (ev, option, index) => {
    getOptionProps({ option, index }).onClick(ev);
    onSelectAction(option[options?.optionLabel]);
  };

  return (
    <>
      <InputContainer {...getRootProps()}>
        {React.cloneElement(children, newInputProps)}
        <EndAdornmentContainer>
          {children.props.endAdornment}
        </EndAdornmentContainer>
      </InputContainer>
      {groupedOptions.length > 0 && !children.props.disabled && (
        <ListBox {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <ListItem
              key={option[options?.optionKey]}
              {...getOptionProps({ option, index })}
              style={{
                display:
                  selectedOptions.includes(option[options?.optionLabel]) &&
                  'none'
              }}
              onClick={(ev) => handleClickSelection(ev, option, index)}
            >
              {option[options?.optionLabel]}
            </ListItem>
          ))}
        </ListBox>
      )}
    </>
  );
};
