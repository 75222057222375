import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';

const notificationssSlice = createSlice({
  name: 'notifications',
  initialState: {
    queue: []
  },
  reducers
});

const { actions, reducer } = notificationssSlice;

export const { addNotification } = actions;
export default reducer;
