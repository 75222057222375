import imagesService from 'services/images';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestImages, responseImages, errorImages } from './imagesSlice';

export const uploadImageThunk = createAsyncThunk(
  'images/uploadImageThunk',
  async (image, thunkAPI) => {
    try {
      thunkAPI.dispatch(requestImages());
      const response = await imagesService.uploadImage(image);
      return response.data.url;
    } catch (err) {
      thunkAPI.dispatch(errorImages(err));
    } finally {
      thunkAPI.dispatch(responseImages());
    }
  }
);
