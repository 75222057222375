import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { urls } from 'helpers/navigation';
import { fireNotification } from 'store/notifications/thunks';
import { SEVERITY } from 'components/Notification/Notification';
import { uploadImageThunk } from 'store/images/thunks';
import { setSelectedProduct } from 'store/products/productsSlice';
import { selectedProduct } from 'store/products/selectors';
import {
  getProductByIdThunk,
  updateProductByIdThunk
} from 'store/products/thunks';
import { ProductContent } from 'components/ProductContent/ProductContent';
import { text } from './text';

export const EditProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productId } = useParams();
  const { state } = useLocation();
  const { previousView } = state || {};
  const fromCategory = previousView === 'category';
  const currentProduct = useSelector(selectedProduct);

  const backNavigationAction = () =>
    fromCategory ? navigate(-1) : navigate(urls.catalogList);

  useEffect(() => {
    if (!!productId && productId !== currentProduct?.product_id) {
      dispatch(getProductByIdThunk(productId));
    }
  }, [productId, currentProduct]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedProduct(null));
    };
  }, []);

  const handleUpdateProduct = async (productData, image) => {
    productData.product_id = currentProduct.product_id;
    currentProduct.image && (productData.image = currentProduct.image);

    if (image) {
      const thunkImageResult = await dispatch(uploadImageThunk(image));
      productData.image = thunkImageResult?.payload;
    }

    dispatch(
      updateProductByIdThunk(
        productData,
        () => {
          dispatch(fireNotification(SEVERITY.success, text.productUpdated));
          backNavigationAction();
        },
        () => {
          dispatch(fireNotification(SEVERITY.error, text.productUpdatedError));
          backNavigationAction();
        }
      )
    );
  };

  return (
    <ProductContent
      fromCategory={fromCategory}
      handleProductAction={handleUpdateProduct}
      currentProduct={currentProduct}
    />
  );
};
