import { Divider as MuiDivider } from '@mui/material';
import { forwardRef } from 'react';

export const Divider = forwardRef(function Divider(props, ref) {
  const {
    absolute,
    classes,
    component,
    flexItem,
    light,
    orientation,
    sx,
    textAlign,
    variant,
    ...rest
  } = props;
  return (
    <MuiDivider
      absolute={absolute}
      classes={classes}
      component={component}
      flexItem={flexItem}
      light={light}
      orientation={orientation}
      sx={sx}
      textAlign={textAlign}
      variant={variant}
      ref={ref}
      {...rest}
    ></MuiDivider>
  );
});
