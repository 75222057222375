export const text = {
  newCategory: 'Nueva categoría',
  editCategory: 'Editar categoría',
  addCategory: 'Guardar categoría',
  saveCategoryChanges: 'Guardar cambios',
  subheader: 'Agrega los detalles que definirán tu categoría',
  categoryCreated: 'Se ha creado la categoría.',
  categoryError: 'Se perdió la conexión. Por favor inténtalo de nuevo.',
  categoryUpdated: 'Se actualizó la categoría.',
  productsLoading: 'Estamos cargando tus productos'
};
