import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Card as ClipCard,
  CardHeader as ClipCardHeader
} from '@clipmx/clip-components';
import { Header } from 'components/Header/Header';
import { BlockNavigationModal } from 'components/BlockNavigationModal/BlockNavigationModal';
import { urls } from 'helpers/navigation';
import { selectImagesLoading } from 'store/images/selectors';
import { selectCategoriesLoading } from 'store/categories/selectors';
import { setSelectedItem } from 'store/categories/categoriesSlice';
import { AddCategory } from 'components/CategoryContent/AddCategory/AddCategory';
import { CategoryProductOptions } from 'components/CategoryContent/CategoryProductOptions/CategoryProductOptions';
import {
  categoryDescriptionValidator,
  categoryNameValidator
} from 'helpers/fieldValidations';
import { withFormik } from 'formik';
import {
  ClipCardContentStyled,
  ClipTypographySubheader,
  SectionDivider
} from './styled';
import { text } from './text';

const VIEW_NAME = 'category';
export const CategoryContentComponent = (props) => {
  const {
    dirty,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    resetForm,
    submitForm,
    productList,
    changes,
    currentCategory = null,
    handleCategoryAction
  } = props;

  const isEditMode = !!currentCategory?.category_id;
  const dispatch = useDispatch();
  const { unsavedChanges, setUnsavedChanges } = changes;
  const { categoryProducts, setCategoryProducts } = productList;
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const loadingImage = useSelector(selectImagesLoading);
  const loadingCategory = useSelector(selectCategoriesLoading);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleUpload = (image) => {
    setImage(image.base64);
    setUnsavedChanges(true);
  };

  const isValidData = () => values.name && !errors.name && !errors.description;

  const showUnsavedChanges =
    dirty || unsavedChanges || currentCategory?.isEdited;

  const resetChanges = () => {
    setUnsavedChanges(false);
  };

  const handleModalClose = () => setIsModalOpen(false);
  const handleModalOpen = () => setIsModalOpen(true);

  const handleBackNavigation = () => {
    if (showUnsavedChanges) {
      setIsModalOpen(true);
      return;
    }

    dispatch(setSelectedItem(null));
    navigate(urls.catalogList);
  };

  const onModalLeave = () => {
    resetForm();
    resetChanges();
    dispatch(setSelectedItem(null));
    navigate(urls.catalogList);
  };

  const onCategoryAction = () => {
    submitForm();
    if (isValidData()) {
      const products =
        categoryProducts?.map((item) => ({ id: item.product_id })) ?? [];
      const categoryData = {
        category_name: values.name,
        description: values.description,
        products
      };
      !values.description && delete categoryData.description;
      handleCategoryAction(categoryData, image);
    }
  };

  const navigateTo = (viewUrl) => {
    const isEdited =
      currentCategory?.isEdited ||
      dirty ||
      (image && image !== currentCategory?.image);

    dispatch(
      setSelectedItem({
        category_id: currentCategory?.category_id,
        category_name: values.name,
        description: values.description,
        image: image ?? currentCategory?.image,
        products: categoryProducts,
        isEdited
      })
    );

    navigate(viewUrl, { state: { previousView: VIEW_NAME } });
  };

  return (
    <>
      <ClipCard
        bgColorVariant="100"
        borderRadius={4}
        sx={(theme) => ({
          [theme.breakpoints.down('tablet')]: {
            backgroundColor: theme.palette.surface[50],
            boxShadow: 'none'
          }
        })}
      >
        <ClipCardHeader
          title={
            <Header
              title={isEditMode ? text.editCategory : text.newCategory}
              actionBackArrow={handleBackNavigation}
              textButton={
                isEditMode ? text.saveCategoryChanges : text.addCategory
              }
              actionButton={onCategoryAction}
              loadingButton={loadingImage || loadingCategory}
            />
          }
        />
        <ClipCardContentStyled>
          <ClipTypographySubheader align="left">
            {text.subheader}
          </ClipTypographySubheader>
          <AddCategory
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleUpload={handleUpload}
            imageSrc={currentCategory?.image}
            loading={loadingCategory}
            isEditMode={isEditMode}
          />
          <SectionDivider />
          <CategoryProductOptions
            categoryProducts={categoryProducts}
            setCategoryProducts={setCategoryProducts}
            setUnsavedChanges={setUnsavedChanges}
            categoryId={currentCategory?.category_id}
            navigateTo={navigateTo}
          />
        </ClipCardContentStyled>
      </ClipCard>
      <BlockNavigationModal
        isOpen={isModalOpen}
        handleClose={handleModalClose}
        handleOpen={handleModalOpen}
        onLeave={onModalLeave}
      />
    </>
  );
};

const validationSchema = Yup.object({
  name: categoryNameValidator,
  description: categoryDescriptionValidator
});

export const CategoryContent = withFormik({
  mapPropsToValues: (props) => ({
    name: props.currentCategory?.category_name ?? '',
    description: props.currentCategory?.description ?? ''
  }),
  validationSchema,
  displayName: 'CategoryContent',
  handleSubmit: () => null,
  enableReinitialize: true
})(CategoryContentComponent);
