import { AccordionDetails as ClipAccordionDetails } from '@clipmx/clip-components';
import { ArrowChevronDown } from '@clipmx/clip-icons';
import { useState } from 'react';
import {
  ClipAccordionStyled,
  ClipTypographyStyled,
  ClipAccordionSummaryStyled
} from './styled';

export const Accordion = (props) => {
  const { children, title, defaultOpen = false } = props;
  const [expanded, setExpanded] = useState(defaultOpen);

  const handleChange = (_event, newExpanded) => {
    setExpanded(newExpanded);
  };

  return (
    <ClipAccordionStyled
      expanded={expanded}
      onChange={handleChange}
      sx={(theme) => ({
        '&:before': {
          backgroundColor: theme.palette.surface[50]
        }
      })}
    >
      <ClipAccordionSummaryStyled
        expandIcon={<ArrowChevronDown />}
        aria-controls="panel-content"
        id="panel-header"
      >
        <ClipTypographyStyled>{title}</ClipTypographyStyled>
      </ClipAccordionSummaryStyled>
      <ClipAccordionDetails
        sx={{
          backgroundColor: 'surface.50',
          flexDirection: 'column'
        }}
      >
        {children}
      </ClipAccordionDetails>
    </ClipAccordionStyled>
  );
};
