import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';

const imagesSlice = createSlice({
  name: 'images',
  initialState: {
    loading: false,
    error: {
      message: '',
      code: 418 // i'm a teapot
    }
  },
  reducers
});

const { actions, reducer } = imagesSlice;

export const { requestImages, responseImages, errorImages } = actions;
export default reducer;
