import styled from '@emotion/styled';
import { Alert as ClipAlert } from '@clipmx/clip-components';

export const ClipAlertContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;

export const ClipAlertStyled = styled(ClipAlert)`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;
