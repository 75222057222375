import styled from '@emotion/styled';
import { theme, Typography as ClipTypography } from '@clipmx/clip-components';
import { importantCSS } from 'helpers/overwriteCSS';

export const ClipTypographySubtitle = styled(ClipTypography)`
  ${importantCSS(theme.typography.body1)};
  margin-bottom: 32px;

  ${theme.breakpoints.down('tablet')} {
    ${importantCSS(theme.typography.sm)};
  }
`;
