import { Input as ClipInput } from '@clipmx/clip-components';
import { Scanner } from '@clipmx/clip-icons';
import { Accordion } from 'components/Accordion/Accordion';
import { hasError } from 'helpers/fieldValidations';
import {
  Divider,
  ClipTypographyTitle,
  ClipTypographySubtitle,
  InvertoryInputContainer,
  ClipInputStock,
  ClipInputStockAlert
} from './styled';

import { text } from './text';

export const AdditionalInfo = (props) => {
  const { values, touched, errors, handleChange, handleBlur } = props;

  return (
    <Accordion title={text.additionalInfo}>
      <ClipTypographyTitle color="surface.950">
        {text.specificCharacteristics}
      </ClipTypographyTitle>
      <ClipTypographySubtitle color="surface.700">
        {text.identifyProduct}
      </ClipTypographySubtitle>
      <ClipInput
        name="sku"
        error={hasError('sku', errors, touched)}
        label={text.barCode}
        helperText={
          hasError('sku', errors, touched) ? errors.sku : text.uniqueCode
        }
        endAdornment={<Scanner />}
        type="text"
        value={values.sku}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Divider />

      <ClipTypographyTitle color="surface.950">
        {text.inventory}
      </ClipTypographyTitle>
      <ClipTypographySubtitle color="surface.700">
        {text.manageProductQuantity}
      </ClipTypographySubtitle>
      <InvertoryInputContainer>
        <ClipInputStock
          error={hasError('stock', errors, touched)}
          helperText={hasError('stock', errors, touched) && errors.stock}
          name="stock"
          label={text.inStock}
          type="number"
          value={values.stock}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ inputMode: 'numeric' }}
        />
        <Divider />
        <ClipInputStockAlert
          error={hasError('stockAlert', errors, touched)}
          helperText={
            hasError('stockAlert', errors, touched) && errors.stockAlert
          }
          name="stockAlert"
          label={text.minAlert}
          type="number"
          value={values.stockAlert}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ inputMode: 'numeric' }}
        />
      </InvertoryInputContainer>
    </Accordion>
  );
};
