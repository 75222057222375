import styled from '@emotion/styled';
import {
  theme,
  Typography as ClipTypography,
  IconButton as ClipIconButton,
  Button as ClipButton
} from '@clipmx/clip-components';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${theme.breakpoints.down('tablet')} {
    position: fixed;
    top: 0;
    z-index: 999;
    background-color: ${theme.palette.surface[50]};
    padding-top: 18px;
    padding-bottom: 18px;
    width: 100%;
    margin-left: -16px;
    padding-right: 24px;
  }
`;

export const NavigationContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ClipTypographyTitle = styled(ClipTypography)`
  ${theme.typography.xl};
  font-weight: ${theme.typography.fontWeightMedium};

  ${theme.breakpoints.down('tablet')} {
    display: block;
    width: 100%;
    text-align: center;
    ${theme.typography.sm};
  }
`;

export const ClipIconButtonStyled = styled(ClipIconButton)`
  margin-right: 16px;

  ${theme.breakpoints.down('tablet')} {
    margin-right: 0;
  }
`;

export const ClipButtonStyled = styled(ClipButton)`
  ${theme.typography.body1}
  font-weight: ${theme.typography.fontWeightMedium};
  white-space: nowrap;
  padding: 0 32px;

  ${theme.breakpoints.down('tablet')} {
    ${theme.typography.sm}
    padding: 0;
  }
`;
