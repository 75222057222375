import { useState, useEffect } from 'react';

const TIME_TO_UPDATE = 200;
export const useValueWithTimeout = (
  initialValue = null,
  newValue = null,
  timeoutInMs = TIME_TO_UPDATE
) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setValue(newValue);
    }, timeoutInMs);

    return () => clearTimeout(timeout);
  }, [newValue]);

  return value;
};
