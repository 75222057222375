import styled from '@emotion/styled';
import { theme } from '@clipmx/clip-components';

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const ListBox = styled.ul`
  max-height: 150px;
  width: calc(100% - 410px);
  margin: 0;
  margin-top: 16px;
  padding: 0;
  border-radius: 8px;
  box-shadow: ${theme.shadows.medium.gray};
  z-index: 1;
  list-style: none;
  background-color: ${theme.palette.surface['50']};
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;

  ${theme.breakpoints.down('tablet')} {
    display: none;
  }
`;

export const ListItem = styled.li`
  ${theme.typography.sm}
  font-weight: ${theme.typography.fontWeightMedium};
  color: ${theme.palette.surface['700']};
  cursor: pointer;
  padding: 16px;
  &:hover {
    background-color: ${theme.palette.surface['200']};
  }
`;

export const EndAdornmentContainer = styled.div`
  position: absolute;
  top: 0.8em;
  right: 16px;
  color: ${theme.palette.surface[700]};

  ${theme.breakpoints.down('tablet')} {
    color: ${theme.palette.primary['600']};
  }
`;
