import React from 'react';
import {
  Card as ClipCard,
  Button as ClipButton,
  Alert as ClipAlert,
  Typography as ClipTypography
} from '@clipmx/clip-components';
import { PlusAdd } from '@clipmx/clip-icons';
import { Empty } from '@clipmx/clip-illustrations';
import { useNavigate } from 'react-router-dom';
import { urls } from 'helpers/navigation';
import { EmptyStateContent } from './styled';
import text from './text';

export const EmptyState = () => {
  const navigate = useNavigate();

  return (
    <>
      <ClipCard
        sx={{
          padding: '16px 16px 32px 16px',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <ClipAlert
          size="big"
          sx={{
            fontWeight: 'fontWeightMedium',
            maxWidth: '100%'
          }}
        >
          {text.alertMessage}

          <ClipTypography
            sx={{
              textDecoration: 'underline',
              fontWeight: 'fontWeightMedium',
              display: 'inline',
              cursor: 'pointer'
            }}
          >
            {text.openModal}
          </ClipTypography>
        </ClipAlert>
        <EmptyStateContent>
          <Empty
            sx={{
              width: '231px',
              height: '230px',
              marginTop: '24px'
            }}
          />

          <ClipTypography
            variant="lg"
            sx={{
              fontWeight: 'fontWeightMedium',
              marginTop: '32px'
            }}
          >
            {text.title}
          </ClipTypography>
          <ClipTypography
            align="center"
            color="surface.700"
            sx={{
              marginTop: '8px'
            }}
          >
            {text.message}
          </ClipTypography>
          <ClipButton
            color="primary"
            variant="contained"
            sx={{
              marginTop: '32px',
              marginBottom: '24px',
              padding: '12px 16px'
            }}
            onClick={() => navigate(urls.catalogList)}
          >
            <PlusAdd
              sx={{
                marginRight: '12px',
                pointerEvents: 'none'
              }}
            />
            {text.addButton}
          </ClipButton>
        </EmptyStateContent>
      </ClipCard>
    </>
  );
};
