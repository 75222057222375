export const text = {
  addExistingProduct: 'Producto Existente',
  addNewProduct: 'Producto Nuevo',
  title: 'Productos',
  selectedItems: 'seleccionados',
  productsLoading: 'Estamos cargando tus productos',
  removeProducts: 'Quitar de la categoría',
  selectedElements: (itemsLength) =>
    !itemsLength
      ? ''
      : itemsLength > 1
      ? 'Elementos seleccionados'
      : 'Elemento seleccionado',
  productOptionsTitle:
    'Agrega los productos que podrás encontrar dentro de esta categoría.',
  removeProductTitle: '¿Quieres quitar los productos seleccionados?',
  removeProductDescription:
    'Los productos serán removidos de esta categoría, y podrás encontrarlos únicamente en la sección de productos o dentro de la otra categoría que tengas asignada.',
  removeProductCancel: 'No, conservar',
  removeProductAccept: 'Si, quitar'
};
