import styled from '@emotion/styled';
import {
  theme,
  Typography as ClipTypography,
  IconButton as ClipIconButton
} from '@clipmx/clip-components';

export const ClipTypographyLabel = styled(ClipTypography)`
  ${theme.breakpoints.down('tablet')} {
    display: none;
  }
`;

export const ToggleViewContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ToggleViewActions = styled.div`
  border: 1px solid ${theme.palette.surface[300]};
  border-radius: 8px;
  margin-left: 16px;
  display: flex;
  padding: 4px;
`;

export const ClipIconButtonStyled = styled(ClipIconButton)((props) => ({
  boxShadow: props.selected && `${theme.shadows.medium.gray}`,
  color: !props.selected && theme.palette.surface[500],
  height: '100%',
  padding: '10px',
  border: 'none',

  '&:hover': {
    boxShadow: props.selected && `${theme.shadows.medium.gray}`,
    color: !props.selected && theme.palette.surface[500]
  }
}));
