import { apiClient } from 'services';

const CATEGORIES_ENDPOINT = '/categories';
const addNew = (category) => {
  return apiClient
    .post(CATEGORIES_ENDPOINT, category)
    .then((response) => response.data)
    .catch(() => {
      throw {
        found: true,
        message: 'has ocurred an error in addNewCategory',
        code: 500
      };
    });
};

const getAll = () => {
  return apiClient
    .get(CATEGORIES_ENDPOINT)
    .then((response) => response.data)
    .catch(() => {
      throw {
        found: true,
        message: 'has ocurred an error in getAllCategories',
        code: 500
      };
    });
};

const getById = (categoryId) => {
  return apiClient
    .get(`${CATEGORIES_ENDPOINT}/${categoryId}`)
    .then((response) => response.data)
    .catch(() => {
      throw {
        found: true,
        message: 'has ocurred an error in getCategoryById',
        code: 500
      };
    });
};

const getProductsByCategoryId = (categoryId) => {
  return apiClient
    .get(`${CATEGORIES_ENDPOINT}/${categoryId}/products`)
    .then((response) => response.data)
    .catch(() => {
      throw {
        found: true,
        message: 'has ocurred an error in getCategoryById',
        code: 500
      };
    });
};

const updateById = (category) => {
  return apiClient
    .put(`${CATEGORIES_ENDPOINT}/${category.category_id}`, category)
    .then((response) => response.data)
    .catch(() => {
      throw {
        found: true,
        message: 'has occured an error in updateCategory',
        code: 500
      };
    });
};

const deleteById = (categoryId) => {
  return apiClient
    .delete(`${CATEGORIES_ENDPOINT}/${categoryId}`)
    .then((response) => {
      return response;
    })
    .catch(() => {
      throw {
        found: true,
        message: 'has occured an error in deleteCategory',
        code: 500
      };
    });
};

export default {
  addNew,
  deleteById,
  getAll,
  getById,
  getProductsByCategoryId,
  updateById
};
