import styled from '@emotion/styled';
import {
  theme,
  Typography as ClipTypography,
  Accordion as ClipAccordion,
  AccordionSummary as ClipAccordionSummary
} from '@clipmx/clip-components';
import { importantCSS } from 'helpers/overwriteCSS';

export const ClipTypographyStyled = styled(ClipTypography)`
  ${importantCSS({
    ...theme.typography.lg,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.surface[700]
  })}

  ${theme.breakpoints.down('tablet')} {
    ${importantCSS({
      ...theme.typography.body1,
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.surface[700]
    })}
  }
`;

export const ClipAccordionStyled = styled(ClipAccordion)`
  border-radius: 8px;
  box-shadow: ${theme.shadows.medium.gray};
  margin-top: 16px;
  padding: 24px 0;

  ${theme.breakpoints.down('tablet')} {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 18px 0;
    background-color: transparent;
    box-shadow: ${theme.shadows[0]};
  }
`;

export const ClipAccordionSummaryStyled = styled(ClipAccordionSummary)`
  padding: 0 24px !important;
`;
