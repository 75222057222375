import { APP_ENV } from 'configs/constants';
import Cookies from 'js-cookie';

const getDomainName = (hostName) =>
  hostName.substring(
    hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1
  );

const getCookieDomain = () => {
  const hostname = window ? window.location.hostname : '';
  const domain = getDomainName(hostname);
  return domain === 'netlify.com' ? window.location.hostname : 'localhost';
};

const createCookieName = (env, baseName) => {
  if (env === 'production') return baseName;
  return `${env}_${baseName}`;
};

export const setCookie = (name, value) => {
  const options = { expires: 60, domain: getCookieDomain() };
  const cookieName = createCookieName(APP_ENV, name);
  Cookies.set(cookieName, value, options);
};

export const getCookie = (name) => {
  return Cookies.get(createCookieName(APP_ENV, name));
};
