import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotificationsQueue } from 'store/notifications/selectors';
import { closeNotification } from 'store/notifications/thunks';
import { ClipAlertContainer, ClipAlertStyled } from './styled';

export const SEVERITY = {
  info: 'info',
  warning: 'warning',
  success: 'success',
  error: 'error'
};

export const Notification = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotificationsQueue);

  return createPortal(
    <>
      {notifications.length > 0 && (
        <ClipAlertContainer>
          {notifications.map((notification) => (
            <ClipAlertStyled
              size="medium"
              key={JSON.stringify(notification)}
              severity={notification?.severity}
              onClose={() => dispatch(closeNotification(notification))}
            >
              {notification?.text}
            </ClipAlertStyled>
          ))}
        </ClipAlertContainer>
      )}
    </>,
    document.getElementById('notifications')
  );
};
