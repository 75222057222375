import {
  Button as ClipButton,
  Typography as ClipTypography
} from '@clipmx/clip-components';
import { Refresh as ClipRefresh } from '@clipmx/clip-icons';

import { CardErrorContent, RefreshIllustration } from './styled';
import { text } from './text';

export const CardErrorNetwork = (props) => {
  const { onRefresh = () => null } = props;
  return (
    <CardErrorContent>
      <RefreshIllustration
        viewBox="0 0 230 231"
        style={{ marginTop: '32px' }}
      />
      <ClipTypography
        variant="lg"
        color="surface.950"
        sx={{
          marginTop: '-32px',
          fontWeight: 'fontWeightMedium'
        }}
      >
        {text.title}
      </ClipTypography>
      <ClipTypography
        variant="body"
        align="center"
        color="surface.700"
        sx={{
          marginTop: '8px'
        }}
      >
        {text.description}
      </ClipTypography>
      <ClipButton
        color="primary"
        variant="contained"
        sx={{
          marginTop: '32px',
          padding: '12px 16px'
        }}
        onClick={onRefresh}
      >
        <ClipRefresh
          sx={{
            marginRight: '12px',
            pointerEvents: 'none'
          }}
        />
        {text.button}
      </ClipButton>
    </CardErrorContent>
  );
};
