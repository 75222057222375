import styled from '@emotion/styled';
import {
  theme,
  Menu as ClipMenu,
  Typography as ClipTypography
} from '@clipmx/clip-components';
import { importantCSS } from 'helpers/overwriteCSS';

export const ClipMenuStyled = styled(ClipMenu)`
  ${theme.breakpoints.down('tablet')} {
    ${importantCSS({ display: 'none' })}
  }
`;

export const ClipTypographyMenuItem = styled(ClipTypography)`
  ${importantCSS({
    ...theme.typography.sm,
    fontWeight: theme.typography.fontWeightMedium
  })}
`;

export const ActionButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
