import { useState } from 'react';
import { Accordion } from 'components/Accordion/Accordion';

import {
  theme,
  Switch as ClipSwitch,
  Chip as ClipChip,
  List as ClipList,
  ListItem as ClipListItem,
  ListItemText as ClipListItemText,
  IconButton as ClipIconButton
} from '@clipmx/clip-components';
import { PlusAdd, Dots } from '@clipmx/clip-icons';
import { AddAtributteContainer } from '../AddAttribute/AddAttribute';
import {
  ClipTypographySubtitle,
  ClipTypographyToogle,
  ClipTypographyAddAtribute,
  ViewAddAtribute,
  SwitchProductOptions,
  ClipIconButtonAddAttribute
} from './styled';
import { text } from './text';
const MAX_PRODUCT_OPTIONS = 2;

export const ProductOptions = (props) => {
  const { onAddProductOptions, productOptions } = props;

  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [variants, setVariants] = useState([]);

  const handleChangeSwitch = (event) => {
    setChecked(event.target.checked);
  };

  const handleModal = (condition) => {
    setOpen(condition);
  };

  return (
    <Accordion title={text.title}>
      <ClipTypographySubtitle sx={{ marginBottom: '32px' }}>
        {text.subtitle}
      </ClipTypographySubtitle>
      <SwitchProductOptions>
        <ClipTypographyToogle>{text.textToogle}</ClipTypographyToogle>
        <ClipSwitch checked={checked} onChange={handleChangeSwitch} />
      </SwitchProductOptions>
      {checked && (
        <>
          <ViewAddAtribute>
            {productOptions.map((item) => (
              <ClipList key={item.name}>
                <ClipListItem
                  disableGutters
                  sx={{
                    paddingBottom: theme.spacing(0),
                    marginTop: theme.spacing(-8),
                    paddingTop: theme.spacing(6),
                    [theme.breakpoints.down('tablet')]: {
                      marginTop: theme.spacing(-8),
                      marginBottom: theme.spacing(6),
                      paddingTop: theme.spacing(6)
                    }
                  }}
                >
                  <ClipListItemText primary={item.name} size="big" />
                  <ClipIconButton color="transparent" size="large">
                    <Dots />
                  </ClipIconButton>
                </ClipListItem>
                <ClipListItem
                  disableGutters
                  divider
                  sx={{
                    marginTop: theme.spacing(-8),
                    paddingBottom: theme.spacing(4),
                    [theme.breakpoints.down('tablet')]: {
                      paddingTop: theme.spacing(0)
                    }
                  }}
                >
                  {item.variants.map((variant) => (
                    <ClipChip
                      sx={{
                        maxWidth: '150px',
                        marginLeft: theme.spacing(1),
                        marginTop: theme.spacing(8),
                        [theme.breakpoints.down('tablet')]: {
                          marginTop: theme.spacing(4)
                        }
                      }}
                      key={variant}
                      label={variant}
                      color="surface"
                      size="small"
                      deleteIcon={<Dots />}
                      // this will be removed when the actual functionality is added
                      onDelete={() => console.log('edit or delete')}
                    />
                  ))}
                </ClipListItem>
              </ClipList>
            ))}

            {productOptions.length < MAX_PRODUCT_OPTIONS && (
              <ClipIconButtonAddAttribute
                color="transparent"
                variant="rounded"
                onClick={() => handleModal(true)}
              >
                <ClipTypographyAddAtribute>
                  {text.addAtribute}
                </ClipTypographyAddAtribute>
                <PlusAdd
                  sx={{
                    color: theme.palette.primary['600'],
                    pointerEvents: 'none'
                  }}
                />
              </ClipIconButtonAddAttribute>
            )}
            <AddAtributteContainer
              open={open}
              handleAddProductOptions={onAddProductOptions}
              productOptions={productOptions}
              handleModal={handleModal}
              variants={variants}
              setVariants={setVariants}
            />
          </ViewAddAtribute>
        </>
      )}
    </Accordion>
  );
};
