import styled from '@emotion/styled';
import {
  theme,
  CardContent as ClipCardContent,
  Typography as ClipTypography
} from '@clipmx/clip-components';

export const CatalogListSubheader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 32px;

  ${theme.breakpoints.down('tablet')} {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const ClipTypographySubheader = styled(ClipTypography)`
  ${theme.typography.body1}

  ${theme.breakpoints.down('tablet')} {
    ${theme.typography.sm}
  }
`;

export const ClipCardContentStyled = styled(ClipCardContent)`
  ${theme.breakpoints.down('tablet')} {
    padding-left: 0;
    padding-right: 0;
  }
`;
