import { Dialog as ClipDialog, theme } from '@clipmx/clip-components';
import { useScreenSize } from 'helpers/hooks/useScreenSize';
import { SwipeableDrawer } from 'components/SwipeableDrawer/SwipeableDrawer';
import {
  BlockNavigationMobileButtonsContainer,
  BlockNavigationMobileContainer,
  BlockNavigationMobileItemContainer,
  ClipButtonStyled,
  ClipMobileBodyStyled,
  ClipMobileTitleStyled
} from './styled';
import { text } from './text';

export const BlockNavigationModal = (props) => {
  const { isOpen, handleClose, handleOpen, onLeave } = props;
  const width = useScreenSize();

  const isMobile = () => {
    return width <= theme.breakpoints.values.tablet;
  };

  return isMobile() ? (
    <SwipeableDrawer open={isOpen} onClose={handleClose} onOpen={handleOpen}>
      <BlockNavigationMobileContainer>
        <BlockNavigationMobileItemContainer isTitle>
          <ClipMobileTitleStyled>{text.modalTitle}</ClipMobileTitleStyled>
        </BlockNavigationMobileItemContainer>
        <BlockNavigationMobileItemContainer>
          <ClipMobileBodyStyled>{text.modalBody}</ClipMobileBodyStyled>
        </BlockNavigationMobileItemContainer>
        <BlockNavigationMobileButtonsContainer>
          <ClipButtonStyled
            color="tertiary"
            onClick={handleClose}
            size="large"
            sx={{
              color: theme.palette.surface[700],
              fontWeight: theme.typography.fontWeightBold
            }}
            variant="contained"
          >
            {text.modalCancelBtn}
          </ClipButtonStyled>
          <ClipButtonStyled
            color="primary"
            onClick={onLeave}
            size="large"
            sx={{
              fontWeight: theme.typography.fontWeightBold
            }}
            variant="contained"
          >
            {text.modalAcceptBtn}
          </ClipButtonStyled>
        </BlockNavigationMobileButtonsContainer>
      </BlockNavigationMobileContainer>
    </SwipeableDrawer>
  ) : (
    <ClipDialog
      open={isOpen}
      onClose={handleClose}
      title={text.modalTitle}
      description={text.modalBody}
      primaryButtonLabel={text.modalAcceptBtn}
      primaryButtonAction={onLeave}
      secondaryButtonLabel={text.modalCancelBtn}
      secondaryButtonAction={handleClose}
    />
  );
};
