import { ListViewBullet, TilesViewGridLayout } from '@clipmx/clip-icons';
import {
  ClipTypographyLabel,
  ToggleViewActions,
  ToggleViewContainer,
  ClipIconButtonStyled
} from './styled';
import { text } from './text';
export const LIST_VIEW = 'list';
export const GRID_VIEW = 'grid';

export const ToggleView = (props) => {
  const { view, onToggleView } = props;

  const handleChangeView = (ev) => {
    const value = ev.target.value;
    onToggleView(value);
  };

  const handleSetColor = (viewType) => {
    return view === viewType ? 'surface' : 'transparent';
  };

  return (
    <ToggleViewContainer>
      <ClipTypographyLabel
        variant="sm"
        sx={{
          fontWeight: 'fontWeightMedium',
          color: 'surface.main'
        }}
      >
        {text.view}
      </ClipTypographyLabel>
      <ToggleViewActions>
        <ClipIconButtonStyled
          color={handleSetColor(LIST_VIEW)}
          value={LIST_VIEW}
          onClick={handleChangeView}
          selected={view === LIST_VIEW}
        >
          <ListViewBullet sx={{ pointerEvents: 'none' }} />
        </ClipIconButtonStyled>
        <ClipIconButtonStyled
          color={handleSetColor(GRID_VIEW)}
          value={GRID_VIEW}
          onClick={handleChangeView}
          selected={view === GRID_VIEW}
        >
          <TilesViewGridLayout sx={{ pointerEvents: 'none' }} />
        </ClipIconButtonStyled>
      </ToggleViewActions>
    </ToggleViewContainer>
  );
};
