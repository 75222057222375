import { theme } from '@clipmx/clip-components';
import { generateThumbanilLetterFromName } from 'helpers/utils';
import { ThumbnailLetterStyled } from './styled';

export const ThumbnailLetter = ({ text, ...rest }) => {
  const alphabetColors = new Map();
  alphabetColors.set('A', theme.palette.warning['500']);
  alphabetColors.set('B', theme.palette.primary['800']);
  alphabetColors.set('C', theme.palette.informative['800']);
  alphabetColors.set('D', theme.palette.purple['700']);
  alphabetColors.set('E', theme.palette.cobalt['500']);
  alphabetColors.set('F', theme.palette.surface['800']);
  alphabetColors.set('G', theme.palette.error['600']);
  alphabetColors.set('H', theme.palette.success['500']);
  alphabetColors.set('I', theme.palette.primary['900']);
  alphabetColors.set('J', theme.palette.cobalt['700']);
  alphabetColors.set('K', theme.palette.error['700']);
  alphabetColors.set('L', theme.palette.warning['700']);
  alphabetColors.set('M', theme.palette.success['700']);
  alphabetColors.set('N', theme.palette.purple['400']);
  alphabetColors.set('O', theme.palette.primary['950']);
  alphabetColors.set('P', theme.palette.error['950']);
  alphabetColors.set('Q', theme.palette.informative['950']);
  alphabetColors.set('R', theme.palette.cobalt['600']);
  alphabetColors.set('S', theme.palette.informative['900']);
  alphabetColors.set('T', theme.palette.surface['900']);
  alphabetColors.set('U', theme.palette.success['600']);
  alphabetColors.set('V', theme.palette.purple['600']);
  alphabetColors.set('W', theme.palette.surface['950']);
  alphabetColors.set('X', theme.palette.purple['500']);
  alphabetColors.set('Y', theme.palette.cobalt['400']);
  alphabetColors.set('Z', theme.palette.warning['600']);

  const letter = generateThumbanilLetterFromName(text);
  return (
    <ThumbnailLetterStyled {...rest} color={alphabetColors.get(letter)}>
      {letter}
    </ThumbnailLetterStyled>
  );
};
