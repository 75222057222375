import styled from '@emotion/styled';
import {
  theme,
  Card as ClipCard,
  CardContent as ClipCardContent,
  Button as ClipButton,
  Typography as ClipTypography
} from '@clipmx/clip-components';
import { importantCSS } from 'helpers/overwriteCSS';

export const ClipCardContentStyled = styled(ClipCardContent)`
  ${theme.breakpoints.down('tablet')} {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    background-color: ${theme.palette.surface[50]};
    position: relative;
    top: 0;
    left: 0;
  }
`;

export const ClipTypographySubheader = styled(ClipTypography)`
  ${theme.typography.body1};
  color: ${theme.palette.surface[950]};
  margin-bottom: 32px;

  ${theme.breakpoints.down('tablet')} {
    ${theme.typography.sm}
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const ClipButtonStyled = styled(ClipButton)`
  ${theme.typography.body1}
  font-weight: ${theme.typography.fontWeightMedium};
  white-space: nowrap;
  padding: 0 32px;

  ${theme.breakpoints.down('tablet')} {
    ${theme.typography.sm}
    padding: 0;
    width: 100%;
  }
`;

export const ClipCardContainerStyled = styled(ClipCard)`
  ${theme.breakpoints.down('tablet')} {
    ${importantCSS({
      backgroundColor: `${theme.palette.surface[50]}`,
      boxShadow: 'none',
      height: 'calc(100% - 112px)',
      overflow: 'auto',
      width: '100%'
    })}
  }
`;

export const ClipCardBottomStyled = styled(ClipCard)`
  display: none;

  ${theme.breakpoints.down('tablet')} {
    ${importantCSS({
      backgroundColor: `${theme.palette.surface[50]}`,
      boxShadow: 'none',
      position: 'absolute',
      bottom: 0,
      height: '80px',
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'center',
      justifyContent: 'end',
      width: '100%'
    })}
  }
`;

export const SectionDivider = styled.div`
  height: 24px;

  ${theme.breakpoints.down('tablet')} {
    height: 32px;
    width: 100%;
    background-color: ${theme.palette.surface[200]};
  }
`;
