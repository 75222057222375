import {
  theme,
  IconButton as ClipIconButton,
  Input as ClipInput,
  List as ClipList,
  ListItemButton as ClipListItemButton,
  ListItemText as ClipListItemText
} from '@clipmx/clip-components';
import { Divider } from 'components/Divider/Divider';
import { ArrowRight, PlusAdd } from '@clipmx/clip-icons';
import { hasError } from 'helpers/fieldValidations';
import {
  ClipTypographyTitleSecondDialog,
  TitleContent,
  DialogContent,
  HeaderDialog,
  ClipTypographyMessage
} from './styled';

export const VariantsDialogContent = (props) => {
  const {
    backToInitialDialog,
    title,
    message,
    label,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    optionName,
    handleChangeVariants,
    availableVariants = []
  } = props;

  const handleOptionUsersVariants = () => {
    !errors.variant && handleChangeVariants(values.variant);
  };
  const handleOptionUsersVariantsKeyDown = (ev) => {
    !errors.variant && ev.key === 'Enter' && handleOptionUsersVariants();
  };
  const handleVariantsDefault = (value) => {
    handleChangeVariants(value);
  };

  return (
    <>
      <HeaderDialog>
        <ClipIconButton color="transparent" onClick={backToInitialDialog}>
          <ArrowRight
            sx={{
              pointerEvents: 'none',
              transform: 'rotate(180deg)'
            }}
          />
        </ClipIconButton>
        <TitleContent>
          <ClipTypographyTitleSecondDialog variant="body1">
            {title} {optionName}
          </ClipTypographyTitleSecondDialog>
        </TitleContent>
      </HeaderDialog>
      <Divider
        sx={(theme) => ({
          marginBottom: theme.spacing(6),
          marginLeft: theme.spacing(-8),
          marginRight: theme.spacing(-8),
          borderColor: theme.palette.surface[200],
          [theme.breakpoints.down('tablet')]: {
            marginLeft: `-${theme.spacing(4)}`,
            marginRight: `-${theme.spacing(4)}`
          }
        })}
      />
      <ClipTypographyMessage
        sx={(theme) => ({
          marginBottom: `${theme.spacing(8)} `,
          [theme.breakpoints.down('tablet')]: {
            marginBottom: `${theme.spacing(4)}`
          }
        })}
      >
        {message}
      </ClipTypographyMessage>
      <DialogContent>
        <ClipInput
          autoFocus={!availableVariants.length}
          name="variant"
          label={label}
          value={values.variant}
          onChange={handleChange}
          onBlur={handleBlur}
          error={hasError('variant', errors, touched)}
          helperText={hasError('variant', errors, touched) && errors.variant}
          onKeyDown={(ev) => handleOptionUsersVariantsKeyDown(ev)}
        />
        <ClipIconButton
          color="primary"
          sx={{
            marginLeft: theme.spacing(2),
            marginTop: theme.spacing(2)
          }}
          onClick={() => handleOptionUsersVariants()}
        >
          <PlusAdd />
        </ClipIconButton>
      </DialogContent>
      {availableVariants?.length > 0 &&
        availableVariants.map((item) => (
          <ClipList key={item} sx={{ padding: theme.spacing(0) }}>
            <ClipListItemButton
              sx={(theme) => ({
                padding: `${theme.spacing(5)} ${theme.spacing(6)}`,
                marginX: `-${theme.spacing(8)}`,
                [theme.breakpoints.down('tablet')]: {
                  marginX: `-${theme.spacing(4)}`
                }
              })}
              onClick={() => handleVariantsDefault(item)}
            >
              <ClipListItemText
                sx={{ paddingLeft: theme.spacing(3) }}
                primaryTypographyProps={{
                  color: `${theme.palette.surface[700]} !important`
                }}
                primary={item}
              />
            </ClipListItemButton>
          </ClipList>
        ))}
    </>
  );
};
