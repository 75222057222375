export const requestProducts = (state) => {
  state.loading = true;
};

export const responseProducts = (state) => {
  state.loading = false;
};

export const errorProducts = (state, action) => {
  state.error = action.payload;
};

export const getAllProducts = (state, action) => {
  state.items = action.payload;
};

export const requestSelectedProduct = (state) => {
  state.selectedProduct.loading = true;
};

export const responseSelectedProduct = (state) => {
  state.selectedProduct.loading = false;
};

export const setSelectedProduct = (state, action) => {
  state.selectedProduct.value = action.payload;
};

export const setSelectedProductError = (state, action) => {
  state.selectedProduct.error = action.payload;
};

export default {
  requestProducts,
  responseProducts,
  errorProducts,
  getAllProducts,
  requestSelectedProduct,
  responseSelectedProduct,
  setSelectedProduct,
  setSelectedProductError
};
