import * as Yup from 'yup';

export const hasError = (field, errors, touched) =>
  !!errors?.[field] && touched?.[field];

// Category validations
export const categoryNameValidator = Yup.string()
  .required('Introduce el nombre de la categoría')
  .max(250, 'Nombre máximo hasta 250 caracteres')
  .matches(
    /^[A-Za-zÁÉÍÓÚáéíóúüñÑ0-9\s]+$/g,
    'Introduce una categoría sin caracteres especiales'
  );
export const categoryDescriptionValidator = Yup.string().max(
  250,
  'Descripción máxima hasta 250 caracteres'
);

// Product validations
export const productNameValidator = Yup.string()
  .required('Introduce el nombre del producto')
  .max(255, 'Nombre máximo hasta 255 caracteres')
  .matches(
    /^[A-Za-zÁÉÍÓÚáéíóúüñÑ0-9\s]+$/g,
    'Introduce un producto sin caracteres especiales'
  );
export const productPriceValidator = Yup.number()
  .positive()
  .required('Introduce el precio del producto')
  .max(1000000.0, 'El precio máximo permitido es de $1000000.00 ')
  .min(1.0, 'El precio minimo requerido es de 1.00');
export const productDescriptionValidator = Yup.string().max(
  255,
  'Descripción máxima hasta 255 caracteres'
);
export const productSkuValidator = Yup.string()
  .min(1, 'La longuitud mínima es de 1 caracter')
  .max(50, 'La longuitud máxima es de 50 caracteres')
  .matches(
    /^[A-Za-zÁÉÍÓÚáéíóúüñÑ0-9]+$/g,
    'Introduce un código de barras sin espacios o caracteres especiales'
  );
export const productStockValidator = Yup.number()
  .positive()
  .min(0, 'El valor mínimo requerido es 0')
  .max(1000000, 'El valor máximo permitido es 1000000');
export const productStockAlertValidator = Yup.number()
  .positive()
  .min(0, 'El valor mínimo requerido es 0')
  .max(1000000, 'El valor máximo permitido es 1000000');
export const productCategoryValidator = Yup.string()
  .min(1, 'El minimo de caracteres debe ser 1')
  .max(50, 'Nombre máximo hasta 50 caracteres')
  .matches(
    /^[A-Za-zÁÉÍÓÚáéíóúüñÑ0-9\s]+$/g,
    'Introduce una categoría sin caracteres especiales'
  )
  .trim();

export const attributeNameValidator = Yup.string()
  .required('Introduce el nombre de la opción')
  .min(1, 'La longitud mínima es de 1 caracter')
  .max(50, 'Nombre máximo hasta 50 caracteres')
  .matches(
    /^[A-Za-zÁÉÍÓÚáéíóúüñÑ0-9\s]+$/g,
    'Introduce un nombre sin caracteres especiales'
  );
export const attributeVariantValidator = Yup.string()
  .required('Introduce el nombre de la variante')
  .min(1, 'La longitud mínima es de 1 caracter')
  .max(50, 'Nombre máximo hasta 50 caracteres')
  .matches(
    /^[A-Za-zÁÉÍÓÚáéíóúüñÑ0-9\s]+$/g,
    'Introduce un nombre sin caracteres especiales'
  );
