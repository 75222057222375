export const selectProducts = (state) => state.products.items;
export const selectProductsLoading = (state) => state.products.loading;
export const selectProductsError = (state) => state.products.error;

// selected product selectors
export const selectedProduct = (state) => state.products.selectedProduct.value;
export const selectedProductError = (state) =>
  state.products.selectedProduct.error;
export const selectedProductLoading = (state) =>
  state.products.selectedProduct.loading;
