import {
  theme,
  List as ClipList,
  ListItem as ClipListItem,
  ListItemAvatar as ClipListItemAvatar,
  Avatar as ClipAvatar,
  ListItemText as ClipListItemText,
  Checkbox as ClipCheckBox
} from '@clipmx/clip-components';
import { defaultSecondaryLabelFormatter } from 'helpers/utils';
import { CardItemActions } from 'components/CardItemActions/CardItemActions';
import { useScreenSize } from 'helpers/hooks/useScreenSize';
import { ThumbnailLetterStyled } from './styled';

export const CardListView = (props) => {
  const {
    items,
    attributes = {
      key: '',
      name: '',
      image: '',
      children: { id: '', label: '' },
      price: ''
    },
    selectedItems = {},
    onSelectItem,
    menuActionItems,
    secondaryLabelFormatter = defaultSecondaryLabelFormatter,
    disableSelection = false,
    disableSingleActions = false
  } = props;
  const width = useScreenSize();

  const validateSizeScreen = (theme) => {
    let breakPointSize = theme.breakpoints.values.tablet;

    return width > breakPointSize ? 'big' : 'small';
  };

  return (
    <>
      {items?.map((item) => (
        <ClipList key={item[attributes.key]}>
          <ClipListItem
            disableGutters
            divider
            secondaryAction={
              <CardItemActions
                menuOptions={menuActionItems}
                selectedItem={item}
                disabled={disableSingleActions}
              />
            }
          >
            <ClipCheckBox
              onChange={() => onSelectItem?.(item)}
              checked={selectedItems?.[item[attributes.key]]?.isSelected}
              disabled={disableSelection}
            />
            {item[attributes.image] ? (
              <ClipListItemAvatar>
                <ClipAvatar
                  src={item[attributes.image]}
                  sx={(theme) => ({
                    border: `1px solid ${theme.palette.surface['300']}`,
                    borderRadius: theme.spacing(2),
                    width: theme.spacing(12.5),
                    height: theme.spacing(12.5)
                  })}
                  variant="square"
                />
              </ClipListItemAvatar>
            ) : (
              <ThumbnailLetterStyled text={item[attributes.name]} />
            )}
            <ClipListItemText
              sx={() => ({
                width: '80%',
                [theme.breakpoints.down('tablet')]: {
                  width: '50%'
                }
              })}
              primary={item[attributes.name]}
              primaryTypographyProps={{
                marginLeft: '6px',
                overflow: 'hidden'
              }}
              secondary={secondaryLabelFormatter(item, attributes)}
              secondaryTypographyProps={{
                marginLeft: '6px'
              }}
              size={validateSizeScreen(theme)}
            />
            {!item?.price ? (
              item[attributes.children.id]?.length > 0 && (
                <ClipListItemText
                  primary=""
                  primaryTypographyProps={{
                    textAlign: 'end'
                  }}
                  size={validateSizeScreen(theme)}
                />
              )
            ) : (
              <ClipListItemText
                primary={`$${item[attributes.price]}`}
                primaryTypographyProps={{
                  textAlign: 'end'
                }}
                size={validateSizeScreen(theme)}
              />
            )}
          </ClipListItem>
        </ClipList>
      ))}
    </>
  );
};
