import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const Puller = styled(Box)(({ theme }) => ({
  position: 'absolute',
  height: theme.spacing(1),
  width: theme.spacing(8),
  top: theme.spacing(4),
  left: 'calc(50% - 25px)',
  right: 0,
  backgroundColor: theme.palette.surface[300]
}));
