import { configureStore } from '@reduxjs/toolkit';
import { APP_ENV } from 'configs/constants';
import categoriesReducer from 'store/categories/categoriesSlice';
import productsReducer from 'store/products/productsSlice';
import imagesReducer from 'store/images/imagesSlice';
import notificationsReducer from 'store/notifications/notificationsSlice';

const rootReducer = {
  categories: categoriesReducer,
  products: productsReducer,
  images: imagesReducer,
  notifications: notificationsReducer
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: APP_ENV !== 'production'
});
