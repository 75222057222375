import styled from '@emotion/styled';
import { theme } from '@clipmx/clip-components';

export const ThumbnailLetterStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 2px solid;
  height: 300px;
  width: 300px;
  font-size: ${theme.typography['10xl']};
  font-weight: ${theme.typography.fontWeightBold};
  color: ${theme.palette.surface[50]};
  background-color: ${(props) => props.color ?? theme.palette.primary['400']};

  ${theme.breakpoints.down('tablet')} {
    height: 160px;
    width: 160px;
  }
`;
