import { getCookie } from 'helpers/cookies';

/* eslint-disable no-undef */
export const API_URL = process.env.REACT_APP_API_URL;
export const APP_ENV = process.env.REACT_APP_ENV;
export const AUTHORIZATION_TOKEN = getCookie('access_token');
export const DATADOG_APP_ID = process.env.REACT_APP_DATADOG_APP_ID;
export const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
export const DATADOG_SAMPLE_RATE = {
  dev: 100,
  stage: 100,
  production: 10
};
