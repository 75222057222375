import { theme } from '@clipmx/clip-components';
import {
  SwipeableDrawer as MuiSwipeableDrawer,
  Box as MuiBox
} from '@mui/material';
import { Puller } from './styled';

export const SwipeableDrawer = (props) => {
  const { children, ...rest } = props;

  return (
    <MuiSwipeableDrawer
      {...rest}
      swipeAreaWidth={0}
      anchor={'bottom'}
      sx={{
        '& .MuiDrawer-paperAnchorBottom': {
          borderTopLeftRadius: `${theme.spacing(2)} !important`,
          borderTopRightRadius: `${theme.spacing(2)} !important`,
          backgroundColor: theme.palette.surface[50]
        }
      }}
    >
      <>
        <Puller />
        <MuiBox
          sx={{
            paddingTop: theme.spacing(8)
          }}
        >
          {children}
        </MuiBox>
      </>
    </MuiSwipeableDrawer>
  );
};
