import {
  CardHeader as ClipCardHeader,
  CardContent as ClipCardContent,
  Avatar as ClipAvatar
} from '@clipmx/clip-components';
import { CardItemActions } from 'components/CardItemActions/CardItemActions';
import {
  ClipTypographyPrice,
  ClipTypographyQuantity,
  ClipTypographyName,
  ClipCardStyled,
  ClipCheckboxStyled,
  CardGridContainer,
  ThumbnailLetterStyled
} from './styled';

const Header = ({ image, name, onChange, checked, disableSelection }) => {
  return (
    <>
      <ClipCheckboxStyled
        checked={checked}
        onChange={onChange}
        disabled={disableSelection}
      />
      {image ? (
        <ClipAvatar
          src={image}
          sx={(theme) => ({
            border: `1px solid ${theme.palette.surface['300']}`,
            borderRadius: theme.spacing(2),
            width: '100%',
            height: '148px'
          })}
          variant="square"
        />
      ) : (
        <ThumbnailLetterStyled text={name} />
      )}
    </>
  );
};

export const CardGridView = (props) => {
  const {
    items,
    attributes = {
      key: '',
      name: '',
      image: '',
      children: { id: '', label: '' },
      price: ''
    },
    selectedItems = {},
    onSelectItem,
    menuActionItems,
    disableSelection = false,
    disableSingleActions = false
  } = props;

  return (
    <CardGridContainer>
      {items?.map((item) => (
        <ClipCardStyled borderRadius={2} key={item[attributes.key]}>
          <ClipCardHeader
            sx={{ padding: '16px' }}
            title={
              <Header
                image={item[attributes.image]}
                name={item[attributes.name]}
                onChange={() => onSelectItem?.(item)}
                checked={selectedItems?.[item[attributes.key]]?.isSelected}
                disableSelection={disableSelection}
              />
            }
          />
          <ClipCardContent sx={{ padding: '0 16px' }}>
            <ClipTypographyName variant="body1" color="surface.950">
              {item[attributes.name]}
            </ClipTypographyName>
            <ClipTypographyQuantity
              variant="sm"
              color="surface"
              sx={{ color: 'surface' }}
            >
              {`${item[attributes.children.id]?.length} ${
                attributes.children.label
              }`}
            </ClipTypographyQuantity>
            {!item?.price ? (
              item[attributes.children.id]?.length > 0 && (
                <ClipTypographyPrice
                  variant="body1"
                  color="surface.950"
                ></ClipTypographyPrice>
              )
            ) : (
              <ClipTypographyPrice variant="body1" color="surface.950">
                {`$${item[attributes.price]}`}
              </ClipTypographyPrice>
            )}
            <CardItemActions
              menuOptions={menuActionItems}
              selectedItem={item}
              disabled={disableSingleActions}
            />
          </ClipCardContent>
        </ClipCardStyled>
      ))}
    </CardGridContainer>
  );
};
