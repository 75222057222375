export const requestCategories = (state) => {
  state.loading = true;
};

export const responseCategories = (state) => {
  state.loading = false;
};

export const errorCategories = (state, action) => {
  state.error = action.payload;
};

export const getAllCategories = (state, action) => {
  state.items = action.payload;
};

export const setSelectedItem = (state, action) => {
  state.selectedItem = action.payload;
};

export default {
  requestCategories,
  responseCategories,
  errorCategories,
  getAllCategories,
  setSelectedItem
};
