import styled from '@emotion/styled';
import { theme } from '@clipmx/clip-components';
import { ThumbnailLetter } from 'components/ThumbnailLetter/ThumbnailLetter';

export const ThumbnailLetterStyled = styled(ThumbnailLetter)`
  font-size: ${theme.typography.xl};
  height: ${theme.spacing(12.5)};
  width: ${theme.spacing(12.5)};

  ${theme.breakpoints.down('tablet')} {
    height: ${theme.spacing(12.5)};
    width: ${theme.spacing(12.5)};
  }
`;
