import styled from '@emotion/styled';
import {
  CardActionArea as ClipCardActionArea,
  CardContent as ClipCardContent,
  theme,
  Typography as ClipTypography
} from '@clipmx/clip-components';
import { importantCSS } from 'helpers/overwriteCSS';

export const ClipTypographyTitle = styled(ClipTypography)`
  color: ${theme.palette.surface[600]};
  text-transform: uppercase;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 0.08em;

  ${theme.breakpoints.down('tablet')} {
    ${importantCSS(theme.typography.sm)}
  }
`;

export const ClipTypographySubTitle = styled(ClipTypography)`
  color: ${theme.palette.surface[700]};
  line-height: 20px;
  font-weight: 600;

  ${theme.breakpoints.down('tablet')} {
    ${importantCSS(theme.typography.sm)}
  }
`;

export const Divider = styled.div`
  height: 24px;

  ${theme.breakpoints.down('tablet')} {
    height: 32px;
  }
`;

export const ClipCardContentStyled = styled(ClipCardContent)`
  margin: 0;
  padding: 32px 24px;
`;

export const ClipCardTextContainer = styled.div`
  margin: 8px;
  padding: 0;

  ${theme.breakpoints.down('tablet')} {
    display: none;
  }
`;

export const ClipCardActionAreaStyled = styled(ClipCardActionArea)`
  height: 112px;
  max-width: 50%;
  &:hover {
    border-color: ${theme.palette.surface[300]};
  }
`;

export const ClipCardProductsCategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  margin: 0;

  ${theme.breakpoints.down('tablet')} {
    justify-items: center;
  }
`;

export const ClipCardContainerStyled = styled.div`
  margin: 0;
  padding: 0;

  ${theme.breakpoints.down('tablet')} {
    ${importantCSS({
      position: 'relative',
      backgroundColor: `${theme.palette.surface[100]}`,
      boxShadow: 'none',
      height: 'calc(75vh - 80px)', // update this when a new item gets added to the view
      overflow: 'auto'
    })}
  }
`;

export const SectionDivider = styled.div`
  height: 24px;

  ${theme.breakpoints.down('tablet')} {
    height: 32px;
    width: 100%;
    background-color: ${theme.palette.surface[200]};
  }
`;
