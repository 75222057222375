import { useState } from 'react';
import {
  IconButton as ClipIconButton,
  MenuItem as ClipMenuItem,
  List as ClipList,
  ListItem as ClipListItem,
  theme
} from '@clipmx/clip-components';
import { Dots } from '@clipmx/clip-icons';
import { useScreenSize } from 'helpers/hooks/useScreenSize';
import { SwipeableDrawer } from 'components/SwipeableDrawer/SwipeableDrawer';
import {
  ActionButtonContainer,
  ClipMenuStyled,
  ClipTypographyMenuItem
} from './styled';

export const CardItemActions = ({
  menuOptions = [],
  selectedItem,
  disabled
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const width = useScreenSize();

  const isMobile = () => {
    return width <= theme.breakpoints.values.tablet;
  };

  const handleDotsClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  const handleCardItemClick = (menuOption) => {
    // pass the selected option to the click handler
    // of the current menu option to executed
    // function defined on the menuOptions array
    menuOption?.onClick(selectedItem);
    handleClose();
  };

  return (
    <>
      <ActionButtonContainer>
        <ClipIconButton
          color={disabled ? 'default' : 'transparent'}
          variant="circular"
          onClick={menuOptions?.length ? handleDotsClick : undefined}
          disabled={disabled}
        >
          <Dots sx={{ pointerEvents: 'none' }} />
        </ClipIconButton>
      </ActionButtonContainer>
      {isMobile() ? (
        <SwipeableDrawer
          open={isOpen}
          onClose={handleClose}
          onOpen={handleDotsClick}
        >
          <ClipList>
            {menuOptions?.map((menuOption) => (
              <ClipListItem
                key={menuOption?.label}
                onClick={() => handleCardItemClick(menuOption)}
                divider
                sx={{
                  padding: theme.spacing(8),
                  paddingTop: theme.spacing(2),
                  paddingBottom: theme.spacing(4)
                }}
              >
                <ClipTypographyMenuItem>
                  {menuOption?.label}
                </ClipTypographyMenuItem>
              </ClipListItem>
            ))}
          </ClipList>
        </SwipeableDrawer>
      ) : (
        <ClipMenuStyled
          id="clip-menu-card-styled"
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleClose}
          width={240}
          variant={'menu'}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center'
          }}
          sx={{
            marginTop: '0px',
            marginBottom: '0px',
            '& .MuiBackdrop-invisible': {
              backgroundColor: 'unset'
            },
            ul: {
              padding: 0
            }
          }}
        >
          {menuOptions?.map((menuOption) => (
            <ClipMenuItem
              onClick={() => handleCardItemClick(menuOption)}
              key={menuOption?.label}
            >
              {menuOption?.label}
            </ClipMenuItem>
          ))}
        </ClipMenuStyled>
      )}
    </>
  );
};
