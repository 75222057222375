import styled from '@emotion/styled';
import {
  theme,
  Avatar as ClipAvatar,
  IconButton as ClipIconButton
} from '@clipmx/clip-components';

export const ImagePreviewContainer = styled.div`
  position: relative;
  max-height: 300px;

  ${theme.breakpoints.down('tablet')} {
    max-height: 160px;
  }
`;

export const Uploader = styled.input`
  display: none;
`;

export const ThumbnailImage = styled(ClipAvatar)`
  background-color: ${theme.palette.surface[50]};
  border-radius: 8px;
  border: 2px solid ${theme.palette.surface[300]};
  height: 300px;
  width: 300px;

  ${theme.breakpoints.down('tablet')} {
    height: 160px;
    width: 160px;
  }
`;

export const ClipIconButtonStyled = styled(ClipIconButton)`
  position: absolute;
  bottom: 0;
  right: 0;
`;
