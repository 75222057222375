import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  Card as ClipCard,
  CardHeader as ClipCardHeader
} from '@clipmx/clip-components';
import { BlockNavigationModal } from 'components/BlockNavigationModal/BlockNavigationModal';
import { Header } from 'components/Header/Header';
import { urls } from 'helpers/navigation';
import { getInitialNumberValue } from 'helpers/utils';
import { selectImagesLoading } from 'store/images/selectors';
import {
  selectProductsLoading,
  selectedProductLoading
} from 'store/products/selectors';
import {
  productDescriptionValidator,
  productNameValidator,
  productPriceValidator,
  productSkuValidator,
  productStockAlertValidator,
  productStockValidator,
  productCategoryValidator
} from 'helpers/fieldValidations';
import { withFormik } from 'formik';
import {
  selectCategory,
  selectCategories,
  selectCategoriesLoading
} from 'store/categories/selectors';
import {
  addNewCategoryThunk,
  getAllCategoriesThunk
} from 'store/categories/thunks';
import {
  ClipCardContentStyled,
  ClipTypographySubheader,
  Divider
} from './styled';
import { text } from './text';
import { AddProduct } from './AddProduct/AddProduct';
import { AdditionalInfo } from './AdditionalInfo/AdditionalInfo';
import { ProductOptions } from './ProductOptions/ProductOptions';
import { Variants } from './Variants/Variants';

export const ProductContentComponent = (props) => {
  const {
    values,
    touched,
    dirty,
    errors,
    handleChange,
    handleBlur,
    resetForm,
    submitForm,
    fromCategory = false,
    handleProductAction,
    setFieldValue,
    setFieldTouched,
    currentProduct
  } = props;

  const isEdit = !!currentProduct;
  const [productCategories, setProductCategories] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState('');
  const loadingImage = useSelector(selectImagesLoading);
  const loadingProduct = useSelector(selectProductsLoading);
  const [productOptions, setProductOptions] = useState([]);
  const loadingSelectedProduct = useSelector(selectedProductLoading);
  const loadingCategory = useSelector(selectCategoriesLoading);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const categories = useSelector(selectCategories);
  const currentCategory = useSelector(selectCategory);

  useEffect(() => {
    dispatch(getAllCategoriesThunk());
  }, []);

  useEffect(() => {
    if (isEdit && currentProduct.categories?.length) {
      const currentCategories = currentProduct.categories.map(
        (item) => item.category_name
      );

      setProductCategories(currentCategories);
    }
  }, [isEdit, currentProduct]);

  useEffect(() => {
    if (currentCategory?.category_name && fromCategory) {
      const currentCategories = [...productCategories];
      currentCategories.push(currentCategory.category_name);
      setProductCategories(currentCategories);
    }
  }, [currentCategory, fromCategory]);

  const backNavigationAction = () =>
    fromCategory ? navigate(-1) : navigate(urls.catalogList);

  const handleUpload = (image) => {
    setImage(image.base64);
  };

  const isValidData = () =>
    values.name &&
    !errors.name &&
    !errors.description &&
    !errors.price &&
    !errors.sku &&
    !errors.stock &&
    !errors.stockAlert &&
    !errors.productCategory;

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const onLeave = () => {
    resetForm();
    setIsOpen(false);
    backNavigationAction();
  };

  const onBackNavigation = () => {
    if (dirty || image) {
      handleOpen();
      return;
    }

    backNavigationAction();
  };

  const handleCreateCategories = async () => {
    let categoriesForCreating = [...productCategories];
    const existantCategories = [];
    const promisesResult = [];

    // find all existant categories
    categories.forEach((category) => {
      if (productCategories.includes(category.category_name)) {
        currentCategory?.category_name !== category.category_name &&
          existantCategories.push({ category_id: category.category_id });
        const index = categoriesForCreating.indexOf(category.category_name);
        categoriesForCreating.splice(index, 1);
      }
    });

    // create new categories since they don't exist yet
    const createCategoryPromises = categoriesForCreating.map((categoryName) => {
      return dispatch(
        addNewCategoryThunk({ category_name: categoryName }, (newCategory) => {
          promisesResult.push(newCategory);
        })
      );
    });

    await Promise.allSettled(createCategoryPromises);

    const categoriesCreated = promisesResult.map((result) => {
      return { category_id: result.category_id };
    });

    return [...existantCategories, ...categoriesCreated];
  };

  const onProductAction = async () => {
    submitForm();
    if (isValidData()) {
      const productData = {
        product_name: values.name,
        description: values.description,
        price: values.price,
        sku: values.sku,
        stock: values.stock,
        stock_alert: values.stockAlert
      };

      !values.description && delete productData.description;
      !values.sku && delete productData.sku;
      typeof values.stock !== 'number' && delete productData.stock;
      typeof values.stockAlert !== 'number' && delete productData.stock_alert;
      !values.attribute && delete productData.attribute;

      productCategories.length &&
        (productData.categories = await handleCreateCategories());

      handleProductAction(productData, image);
    }
  };

  const handleSetProductCategories = (category, index = -1) => {
    if (!productCategories.includes(category)) {
      setProductCategories((prev) => [...prev, category]);
    } else {
      index !== -1 &&
        setProductCategories((prev) =>
          prev.filter((productCategory) => productCategory !== category)
        );
    }
    setFieldTouched('productCategory', false);
    setFieldValue('productCategory', '');
  };

  const handleAddProductOptions = (productOption) => {
    setProductOptions((prev) => [...prev, productOption]);
  };

  return (
    <>
      <ClipCard
        bgColorVariant="100"
        borderRadius={4}
        sx={(theme) => ({
          [theme.breakpoints.down('tablet')]: {
            backgroundColor: theme.palette.surface[50],
            boxShadow: 'none'
          }
        })}
      >
        <ClipCardHeader
          title={
            <Header
              title={isEdit ? text.editProduct : text.newProduct}
              actionBackArrow={onBackNavigation}
              textButton={text.addProduct}
              actionButton={onProductAction}
              loadingButton={
                loadingImage ||
                loadingProduct ||
                loadingCategory ||
                loadingSelectedProduct
              }
            />
          }
        />
        <ClipCardContentStyled>
          <ClipTypographySubheader align="left">
            {text.subheader}
          </ClipTypographySubheader>
          <AddProduct
            values={values}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleUpload={handleUpload}
            productCategories={productCategories}
            onSetProductCategory={handleSetProductCategories}
            isEdit={isEdit}
            imageSrc={currentProduct?.image}
            currentCategory={currentCategory}
          />
          <Divider />
          <ProductOptions
            onAddProductOptions={handleAddProductOptions}
            productOptions={productOptions}
          />
          <Divider />
          {productOptions.length > 0 && (
            <Variants productOptions={productOptions} />
          )}
          <Divider />
          <AdditionalInfo
            values={values}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </ClipCardContentStyled>
      </ClipCard>
      <BlockNavigationModal
        onLeave={onLeave}
        handleClose={handleClose}
        handleOpen={handleOpen}
        isOpen={isOpen}
      />
    </>
  );
};

const validationSchema = Yup.object({
  name: productNameValidator,
  description: productDescriptionValidator,
  price: productPriceValidator,
  sku: productSkuValidator,
  stock: productStockValidator,
  stockAlert: productStockAlertValidator,
  productCategory: productCategoryValidator
});

export const ProductContent = withFormik({
  mapPropsToValues: (props) => ({
    name: props.currentProduct?.product_name ?? '',
    description: props.currentProduct?.description ?? '',
    price: getInitialNumberValue(props.currentProduct?.price),
    sku: props.currentProduct?.sku ?? '',
    stock: props.currentProduct?.stock ?? '',
    stockAlert: props.currentProduct?.stock_alert ?? '',
    productCategory: ''
  }),
  handleSubmit: () => null,
  validationSchema,
  displayName: 'ProductContent',
  enableReinitialize: true
})(ProductContentComponent);
