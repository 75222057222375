import styled from '@emotion/styled';
import {
  theme,
  Typography as ClipTypography,
  Card as ClipCard,
  Checkbox as ClipCheckbox
} from '@clipmx/clip-components';
import { ThumbnailLetter } from 'components/ThumbnailLetter/ThumbnailLetter';
import { importantCSS } from 'helpers/overwriteCSS';

export const CardGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(184px, 1fr));
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  ${theme.breakpoints.between('360', 'tablet')} {
    grid-template-columns: repeat(auto-fill, minmax(47%, 1fr));
  }
`;

export const ClipCardStyled = styled(ClipCard)`
  max-width: 184px;
  width: 100%;

  ${theme.breakpoints.down('tablet')} {
    max-width: 100%;
  }
`;

export const ClipTypographyName = styled(ClipTypography)`
  overflow: hidden;
  ${importantCSS({
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightMedium
  })}

  ${theme.breakpoints.down('tablet')} {
    ${importantCSS(theme.typography.sm)}
  }
`;

export const ClipTypographyQuantity = styled(ClipTypography)`
  display: inline-block;
  margin-bottom: 16px;
  ${importantCSS({ ...theme.typography.sm, color: theme.palette.surface[700] })}

  ${theme.breakpoints.down('tablet')} {
    ${importantCSS({
      ...theme.typography.xs,
      color: theme.palette.surface[700]
    })}
  }
`;

export const ClipTypographyPrice = styled(ClipTypography)`
  ${importantCSS({
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightMedium
  })}

  ${theme.breakpoints.down('tablet')} {
    ${importantCSS({
      ...theme.typography.sm,
      fontWeight: theme.typography.fontWeightMedium
    })}
  }
`;

export const ClipCheckboxStyled = styled(ClipCheckbox)`
  transform: translateX(-8px);
`;

export const ThumbnailLetterStyled = styled(ThumbnailLetter)`
  ${importantCSS({
    width: '100%',
    height: '148px'
  })}
`;

export const CheckBoxPlaceHolderStyled = styled.div`
  height: ${theme.spacing(9.5)};
  width: ${theme.spacing(9.5)};
`;
