import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedItem } from 'store/categories/categoriesSlice';
import { setSelectedProduct } from 'store/products/productsSlice';
import {
  Card as ClipCard,
  CardHeader as ClipCardHeader
} from '@clipmx/clip-components';
import { Trash as ClipTrash } from '@clipmx/clip-icons';
import { Header } from 'components/Header/Header';
import { ConfirmationDialogWithDrawer } from 'components/ConfirmationDialogWithDrawer/ConfirmationDialogWithDrawer';
import { FloatingBottomBar } from 'components/FloatingBottomBar/FloatingBottomBar';
import { SEVERITY } from 'components/Notification/Notification';
import { CardViewContainer } from 'views/CatalogList/CardViewContainer/CardViewContainer';
import {
  GRID_VIEW,
  LIST_VIEW,
  ToggleView
} from 'components/ToggleView/ToggleView';
import { CardGridView } from 'views/CatalogList/CardGridView/CardGridView';
import { CardListView } from 'views/CatalogList/CardListView/CardListView';
import { urls } from 'helpers/navigation';
import { handleSelectItem } from 'helpers/utils';
import {
  selectProducts,
  selectProductsError,
  selectProductsLoading
} from 'store/products/selectors';
import {
  selectCategories,
  selectCategoriesError,
  selectCategoriesLoading
} from 'store/categories/selectors';

import {
  getAllCategoriesThunk,
  deleteCategoriesByIdThunk
} from 'store/categories/thunks';
import { fireNotification } from 'store/notifications/thunks';
import { getAllProductsThunk } from 'store/products/thunks';
import {
  CATEGORY_ATTRIBUTES,
  PRODUCT_ATTRIBUTES,
  ITEM_ID
} from 'views/constants';
import {
  CatalogListSubheader,
  ClipTypographySubheader,
  ClipCardContentStyled
} from './styled';
import { text, deletedItemsText, REPLACE_ITEM_LABEL } from './text';

const SINGLE_ITEM_SELECTED = 1;
export const CatalogList = () => {
  const [view, setView] = useState(LIST_VIEW);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentItem, setCurrentItem] = useState(null);
  const [itemType, setItemType] = useState('');
  const [selectedItems, setSelectedItems] = useState({});
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const products = useSelector(selectProducts);
  const productsError = useSelector(selectProductsError);
  const loadingProducts = useSelector(selectProductsLoading);

  const categories = useSelector(selectCategories);
  const loadingCategories = useSelector(selectCategoriesLoading);
  const categoriesError = useSelector(selectCategoriesError);

  const selectedItemsLength = Object.keys(selectedItems)?.length;
  const isMultiSelection = selectedItemsLength >= SINGLE_ITEM_SELECTED; // from checkboxes not from menu

  useEffect(() => {
    dispatch(getAllCategoriesThunk());
    dispatch(getAllProductsThunk());
    dispatch(setSelectedItem(null));
    dispatch(setSelectedProduct(null));
  }, []);

  const handleToggleView = (currentView) => {
    setView(currentView);
  };

  const haveItems = () => {
    return categories?.length || products?.length;
  };

  const handleRefreshCategory = () => {
    dispatch(getAllCategoriesThunk());
  };

  const handleRefreshProduct = () => {
    dispatch(getAllProductsThunk());
  };

  const handleOpenConfirmationDialog = () => {
    setConfirmationModalOpen(true);
  };

  const handleCloseConfirmationDialog = () => {
    if (currentItem) {
      setCurrentItem(null);
      setItemType('');
    }
    setConfirmationModalOpen(false);
  };

  const categoryMenuActionItems = [
    {
      label: 'Editar',
      onClick: (item) => {
        navigate(`/edit-category/${item?.category_id}`);
      }
    },
    {
      label: 'Eliminar',
      onClick: (item) => {
        setCurrentItem(item);
        setItemType(ITEM_ID.category);
        handleOpenConfirmationDialog();
      }
    }
  ];

  const productMenuActionItems = [
    {
      label: 'Editar',
      onClick: (item) => {
        navigate(`/edit-product/${item?.product_id}`);
      }
    }
  ];

  const handleOnSelectItem = (item) => {
    if (!item) {
      return;
    }

    let newItemType = Object.hasOwn(item, ITEM_ID.category)
      ? ITEM_ID.category
      : ITEM_ID.product;

    const newSelectedItems = handleSelectItem(item, newItemType, selectedItems);

    if (!Object.keys(newSelectedItems)?.length) {
      newItemType = '';
    }

    setItemType(newItemType);
    setSelectedItems(newSelectedItems);
  };

  const handleDeleteItems = () => {
    const itemsToDelete = currentItem?.[itemType]
      ? [currentItem[itemType]]
      : Object.keys(selectedItems);

    const itemsToDeleteLength = itemsToDelete?.length;
    if (!itemsToDeleteLength) {
      return;
    }

    handleCloseConfirmationDialog();
    setSelectedItems({});

    if (itemType === ITEM_ID.category) {
      dispatch(
        deleteCategoriesByIdThunk(
          itemsToDelete,
          () => {
            dispatch(
              fireNotification(
                SEVERITY.success,
                deletedItemsText(
                  itemType,
                  'success',
                  itemsToDeleteLength > SINGLE_ITEM_SELECTED
                )
              )
            );
            setItemType('');
          },
          () => {
            dispatch(
              fireNotification(
                SEVERITY.error,
                deletedItemsText(
                  itemType,
                  'error',
                  itemsToDeleteLength > SINGLE_ITEM_SELECTED
                )
              )
            );
            setItemType('');
          }
        )
      );
    }
  };

  const disableSelectionForCategories = itemType === ITEM_ID.product;
  const disableSelectionForProducts = itemType === ITEM_ID.category;

  return (
    <>
      <ClipCard
        bgColorVariant="100"
        borderRadius={4}
        sx={(theme) => ({
          [theme.breakpoints.down('tablet')]: {
            backgroundColor: theme.palette.surface[50],
            boxShadow: 'none'
          }
        })}
      >
        <ClipCardHeader
          title={
            <Header
              title={text.catalogCreate}
              hideBackArrow={haveItems()}
              actionBackArrow={() => navigate(urls.catalogEmpty)}
            />
          }
        />
        <ClipCardContentStyled>
          <CatalogListSubheader>
            <ClipTypographySubheader align="left">
              {text.addProductsOrCategories}
            </ClipTypographySubheader>
            <ToggleView view={view} onToggleView={handleToggleView} />
          </CatalogListSubheader>
          {/* Categories */}
          <CardViewContainer
            title={text.categories}
            subtitle={text.categorySubtitle}
            addLabel={text.addCategory}
            count={categories?.length}
            onNavigate={() => navigate(urls.newCategory)}
            loading={loadingCategories}
            canCreate={!categoriesError.found}
            networkIssue={categoriesError.found}
            handleRefresh={handleRefreshCategory}
          >
            {view === GRID_VIEW ? (
              <CardGridView
                items={categories}
                attributes={CATEGORY_ATTRIBUTES}
                menuActionItems={categoryMenuActionItems}
                selectedItems={selectedItems}
                onSelectItem={handleOnSelectItem}
                disableSelection={disableSelectionForCategories}
                disableSingleActions={isMultiSelection}
              />
            ) : (
              <CardListView
                items={categories}
                attributes={CATEGORY_ATTRIBUTES}
                menuActionItems={categoryMenuActionItems}
                selectedItems={selectedItems}
                onSelectItem={handleOnSelectItem}
                disableSelection={disableSelectionForCategories}
                disableSingleActions={isMultiSelection}
              />
            )}
          </CardViewContainer>
          {/* Products */}
          <CardViewContainer
            title={text.products}
            subtitle={text.productSubtitle}
            addLabel={text.addProduct}
            count={products?.length}
            onNavigate={() => navigate(urls.newProduct)}
            loading={loadingProducts}
            canCreate={!productsError.found}
            networkIssue={productsError.found}
            handleRefresh={handleRefreshProduct}
          >
            {view === GRID_VIEW ? (
              <CardGridView
                items={products}
                attributes={PRODUCT_ATTRIBUTES}
                menuActionItems={productMenuActionItems}
                disableSelection={disableSelectionForProducts}
                disableSingleActions={isMultiSelection}
              />
            ) : (
              <CardListView
                items={products}
                attributes={PRODUCT_ATTRIBUTES}
                menuActionItems={productMenuActionItems}
                disableSelection={disableSelectionForProducts}
                disableSingleActions={isMultiSelection}
              />
            )}
          </CardViewContainer>
        </ClipCardContentStyled>
      </ClipCard>
      <ConfirmationDialogWithDrawer
        transitionDuration={0}
        open={confirmationModalOpen}
        onClose={handleCloseConfirmationDialog}
        handlePrimaryAction={handleDeleteItems}
        handleSecondaryAction={handleCloseConfirmationDialog}
        text={text.dialog({
          itemType: `${REPLACE_ITEM_LABEL[itemType]}${
            isMultiSelection ? '_multi' : ''
          }`,
          itemName: currentItem?.category_name || currentItem?.product_name
        })}
      />
      <FloatingBottomBar
        title={text.selectedItemsTitle(selectedItemsLength)}
        numberOfItems={selectedItemsLength}
        primaryButton={{
          onClick: handleOpenConfirmationDialog,
          text: text.removeSelectedItemsText,
          icon: ClipTrash
        }}
      />
    </>
  );
};
