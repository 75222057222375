import styled from '@emotion/styled';
import {
  theme,
  Input as ClipInput,
  Typography as ClipTypography
} from '@clipmx/clip-components';

export const ClipTypographyLabel = styled(ClipTypography)`
  position: absolute;
  top: 16px;
  left: 16px;
  color: ${(props) =>
    props.error ? theme.palette.error[700] : theme.palette.surface[700]};
  font-weight: ${theme.typography.fontWeightMedium};
`;

export const InputPriceContainer = styled.div`
  position: relative;
`;

export const ClipInputPrice = styled(ClipInput)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;

  input {
    display: flex;
    align-items: center;
    padding-top: 0;
    max-width: 300px;
    width: ${(props) =>
      props.value.length > 4 ? `${props.value.length * 8.5}px` : '40px'};

    ${theme.breakpoints.down('tablet')} {
      max-width: 60px;
    }
  }
`;

export const ClipTypographyPrefix = styled(ClipTypography)`
  color: ${theme.palette.surface[600]};
  font-weight: ${theme.typography.fontWeightMedium};
  margin-right: 8px;
`;

export const InputPrefixContainer = styled.div`
  display: flex;
`;
