import { addNotification } from './notificationsSlice';

export const fireNotification =
  (severity, text, timeout = 3000) =>
  (dispatch, getState) => {
    const notifications = getState().notifications.queue;
    const currentQueue = [...notifications, { severity, text }];
    dispatch(addNotification(currentQueue));

    setTimeout(() => {
      // remove all notifications
      dispatch(addNotification([]));
    }, timeout);
  };

export const closeNotification = (notification) => (dispatch, getState) => {
  const notifications = getState().notifications.queue;
  const result = notifications.filter((n) => n !== notification);
  dispatch(addNotification(result));
};
