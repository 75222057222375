export const REPLACE_ITEM_LABEL = {
  category_id: 'category',
  product_id: 'product'
};

const DELETE_ITEMS_DESCRIPTION = (itemType = '') => {
  const CATEGORY_TEXT =
    'Las categorías serán removidas de los productos que las contengan. No se eliminarán los productos.';
  return {
    category: CATEGORY_TEXT,
    category_multi: CATEGORY_TEXT,
    product: 'Este producto, junto con su información serán eliminados.',
    product_multi: 'Estos productos, junto con su información serán eliminados.'
  }[itemType];
};

const DELETE_ITEMS_TITLE = (itemType = '', itemName = '') =>
  ({
    category: `¿Quieres eliminar la categoría de ${itemName}?`,
    category_multi: '¿Quieres eliminar las categorías seleccionadas?',
    product: '¿Quieres eliminar este producto?',
    product_multi: '¿Quieres eliminar los productos seleccionados?'
  }[itemType]);

export const text = {
  catalogCreate: 'Creación de catálogo',
  addProductsOrCategories: 'Agrega productos o categorías a tu catálogo.',
  categories: 'Categorías',
  categorySubtitle:
    'Agrega productos y agrúpalos por tipo, clase u orden específico.',
  addCategory: 'Agregar categoría',
  products: 'Productos',
  productSubtitle:
    'Agrega productos con características individuales sin ninguna categoría específica.',
  addProduct: 'Agregar producto',
  selectedItemsTitle: (itemsLength) =>
    !itemsLength
      ? ''
      : itemsLength > 1
      ? 'Elementos seleccionados'
      : 'Elemento seleccionado',
  removeSelectedItemsText: 'Eliminar',
  dialog: ({ itemType = '', itemName = '' }) => ({
    title: DELETE_ITEMS_TITLE(itemType, itemName) || '',
    description: DELETE_ITEMS_DESCRIPTION(itemType) || '',
    primaryLabel: 'Si, eliminar',
    secondaryLabel: 'No, conservar'
  })
};

const DELETED_ITEMS_TEXT_MESSAGE = {
  category_success: 'Se eliminó la categoría',
  category_success_multi: 'Se eliminaron las categorías',
  category_error: 'Ha ocurrido un error al intentar elminar la categoría',
  category_error_multi:
    'Ha ocurrido un error al intentar eliminar las categorías',
  product_success: 'Se eliminó el producto',
  product_success_multi: 'Se eliminaron los productos',
  product_error: 'Ha ocurrido un error al intentar elminar el producto',
  product_error_multi: 'Ha ocurrido un error al intentar eliminar los productos'
};

export const deletedItemsText = (
  itemType,
  alertType = 'success',
  isMulti = false
) => {
  const messageType = `${REPLACE_ITEM_LABEL[itemType]}_${alertType}${
    isMulti ? '_multi' : ''
  }`;
  return DELETED_ITEMS_TEXT_MESSAGE[messageType] || '';
};
