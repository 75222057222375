import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { theme } from '@clipmx/clip-components';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import { Notification } from 'components/Notification/Notification';
import {
  APP_ENV,
  DATADOG_APP_ID,
  DATADOG_CLIENT_TOKEN,
  DATADOG_SAMPLE_RATE
} from 'configs/constants';
import { store } from './store';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { isLocalEnv } from './helpers/environments';

const container = document.getElementById('root');
const root = createRoot(container);

// disable datadog RUM in local environment
if (!isLocalEnv()) {
  datadogRum.init({
    applicationId: DATADOG_APP_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    env: APP_ENV,
    sampleRate: DATADOG_SAMPLE_RATE[APP_ENV],
    trackInteractions: true
  });

  datadogRum.startSessionReplayRecording();
}

root.render(
  <React.StrictMode>
    <StyledEngineProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Provider store={store}>
            <Notification />
            <App />
          </Provider>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
