import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Card as ClipCard,
  CardContent as ClipCardContent,
  CardHeader as ClipCardHeader,
  theme
} from '@clipmx/clip-components';
import { getAllProductsThunk } from 'store/products/thunks';
import {
  selectProducts,
  selectProductsError,
  selectProductsLoading
} from 'store/products/selectors';
import { CardErrorNetwork } from 'components/CardErrorNetwork/CardErrorNetwork';
import { BlockNavigationModal } from 'components/BlockNavigationModal/BlockNavigationModal';
import { Loader } from 'components/Loader/Loader';
import { Header } from 'components/Header/Header';
import { CardListView } from 'views/CatalogList/CardListView/CardListView';
import { PRODUCT_ATTRIBUTES } from 'views/constants';
import {
  ClipButtonStyled,
  ClipCardContentStyled,
  ClipCardBottomStyled,
  ClipCardContainerStyled,
  ClipTypographySubheader,
  SectionDivider
} from './styled';
import { text } from './text';

export const ExistingProductsContent = (props) => {
  const { categoryProducts = [], setCategoryProducts } = props;
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [tempChanges, setTempChanges] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState({});
  const dispatch = useDispatch();
  const loadingProducts = useSelector(selectProductsLoading);
  const productsError = useSelector(selectProductsError);
  const products = useSelector(selectProducts);

  useEffect(() => {
    dispatch(getAllProductsThunk());
  }, []);

  const backNavigation = () => {
    navigate(-1);
  };

  const handleOnSelectItem = (item) => {
    const itemId = item?.product_id;

    if (!itemId) {
      return;
    }

    const newSelectedProducts = { ...selectedProducts };
    if (!newSelectedProducts[itemId]) {
      newSelectedProducts[itemId] = {
        isSelected: true,
        value: item
      };
    } else {
      delete newSelectedProducts[itemId];
    }

    setSelectedProducts(newSelectedProducts);
    setTempChanges(true);
  };

  const handleAddExistingProduct = () => {
    const selectedExistingProducts = [...categoryProducts];
    Object.keys(selectedProducts).forEach((currentProductId) => {
      const currentProduct = selectedProducts?.[currentProductId]?.value;

      if (!currentProduct) {
        return;
      }

      const productFound = selectedExistingProducts.find(
        (item) => item?.product_id === currentProductId
      );

      if (!productFound) {
        selectedExistingProducts.push(currentProduct);
      }
    });

    setSelectedProducts({});
    setTempChanges(false);
    setIsOpen(false);
    setCategoryProducts(selectedExistingProducts);
    backNavigation();
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleBackArrowButton = () => {
    if (tempChanges) {
      handleOpen();
      return;
    }

    setTempChanges(false);
    setSelectedProducts({});
    backNavigation();
  };

  const onLeave = () => {
    setTempChanges(false);
    setSelectedProducts({});
    backNavigation();
  };

  return (
    <>
      <ClipCard
        bgColorVariant="100"
        borderRadius={4}
        sx={(theme) => ({
          [theme.breakpoints.down('tablet')]: {
            backgroundColor: theme.palette.surface[50],
            boxShadow: 'none'
          }
        })}
      >
        <ClipCardHeader
          title={
            <Header
              title={text.existingProductsTitle}
              actionBackArrow={handleBackArrowButton}
              textButton={`${text.actionButtonText(
                Object.keys(selectedProducts)
              )}`}
              actionButton={handleAddExistingProduct}
              loadingButton={loadingProducts}
              hideActionButton
            />
          }
        />
        <ClipCardContentStyled>
          <ClipTypographySubheader align="left">
            {text.existingProductsSubTitle}
          </ClipTypographySubheader>
        </ClipCardContentStyled>
        <ClipCard
          bgColorVariant="50"
          borderRadius={2}
          sx={(theme) => ({
            [theme.breakpoints.down('tablet')]: {
              position: 'absolute',
              width: '100%',
              top: '125px',
              bottom: '0px',
              display: 'flex'
            }
          })}
        >
          <ClipCardContentStyled>
            {loadingProducts ? (
              <Loader message={text.loadingMessage} />
            ) : productsError?.found ? (
              <CardErrorNetwork
                onRefresh={() => dispatch(getAllProductsThunk())}
              />
            ) : (
              <>
                <ClipCardContainerStyled bgColorVariant="100" borderRadius={2}>
                  <ClipCardContent
                    sx={{
                      backgroundColor: theme.palette.surface[50]
                    }}
                  >
                    <CardListView
                      items={products}
                      attributes={PRODUCT_ATTRIBUTES}
                      onSelectItem={handleOnSelectItem}
                      selectedItems={selectedProducts}
                    />
                  </ClipCardContent>
                </ClipCardContainerStyled>
                <SectionDivider />
                <ClipCardBottomStyled bgColorVariant="100" borderRadius={2}>
                  <ClipCardContent
                    sx={{
                      backgroundColor: theme.palette.surface[50]
                    }}
                  >
                    <ClipButtonStyled
                      color={'primary'}
                      variant={'contained'}
                      size={'large'}
                      onClick={handleAddExistingProduct}
                    >
                      {`${text.actionButtonText(
                        Object.keys(selectedProducts)
                      )}`}
                    </ClipButtonStyled>
                  </ClipCardContent>
                </ClipCardBottomStyled>
              </>
            )}
          </ClipCardContentStyled>
        </ClipCard>
      </ClipCard>
      <BlockNavigationModal
        isOpen={isOpen}
        handleClose={handleClose}
        handleOpen={handleOpen}
        onLeave={onLeave}
      />
    </>
  );
};
