import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { urls } from 'helpers/navigation';
import { ProductContent } from 'components/ProductContent/ProductContent';
import { setSelectedItem } from 'store/categories/categoriesSlice';
import { selectCategory } from 'store/categories/selectors';
import { addNewProductThunk } from 'store/products/thunks';
import { uploadImageThunk } from 'store/images/thunks';
import { fireNotification } from 'store/notifications/thunks';
import { SEVERITY } from 'components/Notification/Notification';
import { text } from './text';

export const NewProduct = () => {
  const { state } = useLocation();
  const { previousView } = state || {};
  const fromCategory = previousView === 'category';
  const currentCategory = useSelector(selectCategory);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const backNavigationAction = () =>
    fromCategory ? navigate(-1) : navigate(urls.catalogList);

  const handleAddNewProductToCategory = (productCreated) => {
    if (currentCategory) {
      const editedCategory = {
        ...currentCategory,
        isEdited: true,
        products: [...currentCategory.products, productCreated]
      };

      dispatch(setSelectedItem(editedCategory));
    }
  };

  const handleCreateProduct = async (productData, image) => {
    if (image) {
      const thunkImageResult = await dispatch(uploadImageThunk(image));
      productData.image = thunkImageResult?.payload;
    }

    dispatch(
      addNewProductThunk(
        productData,
        (productCreated) => {
          dispatch(fireNotification(SEVERITY.success, text.productCreated));
          fromCategory && handleAddNewProductToCategory(productCreated);
          backNavigationAction();
        },
        () => {
          dispatch(fireNotification(SEVERITY.error, text.productError));
          backNavigationAction();
        }
      )
    );
  };

  return (
    <ProductContent
      fromCategory={fromCategory}
      handleProductAction={handleCreateProduct}
    />
  );
};
