import styled from '@emotion/styled';
import {
  Button as ClipButton,
  theme,
  Typography as ClipTypography
} from '@clipmx/clip-components';

export const BlockNavigationMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${theme.spacing(8)} ${theme.spacing(4)};
`;

export const BlockNavigationMobileItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${theme.spacing(2)};
`;

export const BlockNavigationMobileButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${theme.spacing(8)};
  gap: ${theme.spacing(4)};
`;

export const ClipButtonStyled = styled(ClipButton)`
  width: 100%;
  white-space: nowrap;
`;

export const ClipMobileTitleStyled = styled(ClipTypography)`
  ${theme.typography.subtitle1}
  font-weight: ${theme.typography.fontWeightMedium};
  color: ${theme.palette.surface['950']};
`;

export const ClipMobileBodyStyled = styled(ClipTypography)`
  ${theme.typography.sm}
  font-weight: ${theme.typography.fontWeightRegular};
  color: ${theme.palette.surface['700']};
`;
