export const text = {
  title: 'Nueva opción',
  description: 'Agrega la información de tu opción y sus variantes.',
  optionsDetails: 'Detalles de la opción',
  optionNameLabel: 'Nombre de la opción',
  example: 'Ejemplo: Color',
  variants: 'Variantes',
  AddVariantsLabel: 'Agregar nueva variante',
  exampleVariants: 'Ejemplo: Negro',
  cancelButton: 'Cancelar',
  saveButton: 'Guardar',
  optionMessageSameName: 'No se puede agregar la misma opción',
  optionMessageError: 'Introduce un nombre para la opción',
  optionMessageErrorNoOption:
    'Agrega una opción para seleccionar las variantes',
  variantsMessageSameName: 'no se puede repetir el nombre de la variante',
  variantMessageErrorNoVariant: (optionName) =>
    `Introduce variantes para ${optionName}`,
  variantMessageError: 'Introduce variantes',
  atribute: {
    title: 'Nombre de la opción',
    message: 'Selecciona uno de la lista o crea uno nuevo.',
    addLabel: 'Agregar nueva opción'
  },
  variant: {
    title: 'Variante para',
    message: 'Selecciona uno de la lista o crea uno nuevo.',
    addLabel: 'Agregar nueva variante'
  }
};
