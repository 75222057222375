import { text as catalogListText } from 'views/CatalogList/text';

export const ITEM_ID = {
  category: 'category_id',
  product: 'product_id'
};

export const CATEGORY_INITIAL_VIEW = 'CATEGORY_INITIAL_VIEW';
export const CATEGORY_ADD_EXISTING_PRODUCT = 'CATEGORY_ADD_EXISTING_PRODUCT';
export const CATEGORY_CREATE_PRODUCT = 'CATEGORY_CREATE_PRODUCT';

export const PRODUCT_VIEWS = {
  EXISTING_PRODUCT: 'GO_TO_EXISTING_PRODUCT',
  NEW_PRODUCT: 'GO_TO_CREATE_PRODUCT'
};

export const CATEGORY_ATTRIBUTES = {
  key: 'category_id',
  name: 'category_name',
  image: 'image',
  children: {
    id: 'products',
    label: catalogListText.products.toLowerCase()
  }
};

export const PRODUCT_ATTRIBUTES = {
  key: 'product_id',
  name: 'product_name',
  image: 'image',
  children: {
    id: 'categories',
    label: catalogListText.categories.toLowerCase()
  },
  price: 'price'
};
