export const text = {
  additionalInfo: 'Información adicional (no obligatorio)',
  specificCharacteristics: 'Características específicas',
  identifyProduct:
    'Detalles que complementan tu producto para identificarlo de mejor manera.',
  barCode: 'Código de barras',
  uniqueCode: 'Es el código único del producto.',

  inventory: 'Inventario',
  manageProductQuantity:
    'Administra de cantidad de productos que tienes en tu negocio.',
  inStock: 'En existencia',
  minAlert: 'Alerta de mínimo'
};
