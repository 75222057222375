import { CircularProgress as ClipCircularProgress } from '@clipmx/clip-components';
import { ClipTypographyAddLabel, LoaderContent } from './styled';

export const Loader = ({ message = 'Cargando tu información' }) => {
  return (
    <LoaderContent>
      <ClipCircularProgress sx={{ marginRight: '16px' }} />
      <ClipTypographyAddLabel>{`${message} ...`}</ClipTypographyAddLabel>
    </LoaderContent>
  );
};
