import { useState } from 'react';
import {
  theme,
  Button as ClipButton,
  Chip as ClipChip
} from '@clipmx/clip-components';
import { ArrowChevronRight, Close, Error } from '@clipmx/clip-icons';
import {
  ADD_ATTRIBUTE_VIEW,
  ADD_VARIANT_VIEW
} from 'components/ProductContent/constants';
import { text } from './text';
import {
  ClipTypographyTitleDialog,
  ClipTypographyDetails,
  ClipTypographyVariants,
  ClipTypographyDescription,
  ClipSaveButton,
  ActionZone,
  ClipTypographyLabel,
  ClipButtonNextDialog,
  ClipTypographyHelperText,
  SpaceButton,
  ClipTypographyLabelOption,
  ClipTypographyOption,
  ChangeStateButton
} from './styled';

export const AttributeWithVariantsDialogContent = (props) => {
  const {
    handleChangeDialog,
    optionName,
    variants,
    handleClose,
    handleAddProductOptions,
    handleDeletevariant,
    filterDefaulOptions
  } = props;
  const [hasOptionError, sethasOptionError] = useState(false);
  const [hasVariantError, sethasVariantError] = useState(false);
  const [textOptions, setTextOptions] = useState('');
  const [textVariants, setTextVariants] = useState('');

  const handleSaveOptions = () => {
    if (optionName !== '' && variants.length !== 0) {
      handleAddProductOptions({ name: optionName, variants });
      handleClose();
      filterDefaulOptions(optionName);
    } else {
      validateOption();
      validateVariants();
    }
  };

  const validateOption = () => {
    sethasOptionError(true);
    if (!optionName) {
      setTextOptions(text.optionMessageError);
    }
  };

  const validateVariants = () => {
    sethasVariantError(true);
    if (!optionName && !variants.length) {
      setTextVariants(text.variantMessageError);
    } else if (optionName && !variants.length) {
      setTextVariants(text.variantMessageErrorNoVariant(optionName));
    }
  };

  const validateOptionName = (nextView) => {
    if (optionName !== '') {
      handleChangeDialog(nextView);
    } else {
      sethasOptionError(true);
      setTextOptions(text.optionMessageErrorNoOption);
    }
  };

  const changeColor = (validator) => {
    return !validator ? theme.palette.error[700] : theme.palette.surface[700];
  };

  const isDisabled = variants.length === 10;
  return (
    <>
      <ClipTypographyTitleDialog sx={{ marginTop: `-${theme.spacing(6)}` }}>
        {text.title}
      </ClipTypographyTitleDialog>
      <ClipTypographyDescription variant="body1" color="surface.950">
        {text.description}
      </ClipTypographyDescription>
      <ClipTypographyDetails>{text.optionsDetails}</ClipTypographyDetails>
      {optionName !== '' ? (
        <ClipButtonNextDialog
          color="transparent"
          onClick={() => handleChangeDialog(ADD_ATTRIBUTE_VIEW)}
          sx={{ borderColor: theme.palette.surface[300] }}
        >
          <ChangeStateButton>
            <ClipTypographyLabelOption>
              {text.optionNameLabel}
            </ClipTypographyLabelOption>
            <ClipTypographyOption>{optionName}</ClipTypographyOption>
          </ChangeStateButton>
          <ArrowChevronRight />
        </ClipButtonNextDialog>
      ) : (
        <ClipButtonNextDialog
          color="transparent"
          onClick={() => handleChangeDialog(ADD_ATTRIBUTE_VIEW)}
          sx={{
            borderColor: hasOptionError
              ? theme.palette.error[700]
              : theme.palette.surface[300]
          }}
        >
          <ClipTypographyLabel sx={{ color: changeColor(!hasOptionError) }}>
            {text.optionNameLabel}
          </ClipTypographyLabel>
          {!hasOptionError ? (
            <ArrowChevronRight />
          ) : (
            <Error style={{ color: theme.palette.error[700] }} />
          )}
        </ClipButtonNextDialog>
      )}
      <ClipTypographyHelperText sx={{ color: changeColor(!hasOptionError) }}>
        {!hasOptionError ? text.example : textOptions}
      </ClipTypographyHelperText>
      <ClipTypographyVariants>{text.variants}</ClipTypographyVariants>
      <ClipButtonNextDialog
        disabled={isDisabled}
        color="transparent"
        onClick={() => validateOptionName(ADD_VARIANT_VIEW)}
        sx={{
          borderColor: hasVariantError
            ? theme.palette.error[700]
            : theme.palette.surface[300]
        }}
      >
        <ClipTypographyLabel sx={{ color: changeColor(!hasVariantError) }}>
          {text.AddVariantsLabel}
        </ClipTypographyLabel>
        {!hasVariantError ? (
          <ArrowChevronRight />
        ) : (
          <Error style={{ color: theme.palette.error[700] }} />
        )}
      </ClipButtonNextDialog>
      <ClipTypographyHelperText sx={{ color: changeColor(!hasVariantError) }}>
        {!hasVariantError ? text.exampleVariants : textVariants}
      </ClipTypographyHelperText>
      <div>
        {variants.map((item) => (
          <ClipChip
            sx={{
              maxWidth: '100px',
              marginLeft: theme.spacing(1),
              marginTop: theme.spacing(6),
              [theme.breakpoints.down('tablet')]: {
                marginTop: theme.spacing(4)
              }
            }}
            key={item}
            label={item}
            color="surface"
            size="small"
            deleteIcon={<Close />}
            onDelete={() => handleDeletevariant(item)}
          />
        ))}
      </div>
      <ActionZone>
        <ClipButton
          size="large"
          color="tertiary"
          variant="contained"
          sx={{
            width: '50%'
          }}
          onClick={handleClose}
        >
          {text.cancelButton}
        </ClipButton>
        <SpaceButton />
        <ClipSaveButton
          size="large"
          color="primary"
          variant="contained"
          sx={{
            width: '50%'
          }}
          onClick={handleSaveOptions}
        >
          {text.saveButton}
        </ClipSaveButton>
      </ActionZone>
    </>
  );
};
