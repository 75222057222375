import {
  Card as ClipCard,
  CardContent as ClipCardContent,
  CircularProgress as ClipCircularProgress,
  Typography as ClipTypography
} from '@clipmx/clip-components';
import { urls } from 'helpers/navigation';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import {
  selectCategories,
  selectCategoriesLoading
} from 'store/categories/selectors';
import { getAllCategoriesThunk } from 'store/categories/thunks';
import {
  selectProducts,
  selectProductsLoading
} from 'store/products/selectors';
import { getAllProductsThunk } from 'store/products/thunks';

const LoadingSplash = () => {
  return (
    <ClipCard
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '64px 0'
      }}
    >
      <ClipCardContent>
        <ClipCircularProgress />
      </ClipCardContent>
      <ClipTypography
        color="surface.700"
        sx={{ fontWeight: 'fontWeightMedium' }}
      >
        Estamos cargando tu catálogo...
      </ClipTypography>
    </ClipCard>
  );
};

export const VerifyInformation = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const categories = useSelector(selectCategories);
  const products = useSelector(selectProducts);
  const categoriesLoading = useSelector(selectCategoriesLoading);
  const productsLoading = useSelector(selectProductsLoading);

  useEffect(() => {
    dispatch(getAllCategoriesThunk());
    dispatch(getAllProductsThunk());
  }, []);

  return categoriesLoading || productsLoading ? (
    <LoadingSplash />
  ) : categories?.length > 0 || products?.length > 0 ? (
    <Navigate to={urls.catalogList} state={{ from: location }} replace />
  ) : (
    children
  );
};
