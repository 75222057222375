import axios from 'axios';
import { API_URL, AUTHORIZATION_TOKEN } from 'configs/constants';

export const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: AUTHORIZATION_TOKEN,
    accept: 'application/vnd.com.payclip.v1+json'
  }
});
