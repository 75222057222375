import { useEffect, forwardRef } from 'react';
import { mask, getInitialFormattedPrice } from 'helpers/currency';
import { useRef, useState } from 'react';
import {
  InputPriceContainer,
  ClipTypographyLabel,
  ClipInputPrice,
  ClipTypographyPrefix,
  InputPrefixContainer
} from './styled';

const InputWithPrefix = forwardRef(function NumberFormatCustom(props, ref) {
  return (
    <InputPrefixContainer>
      <ClipTypographyPrefix>$</ClipTypographyPrefix>
      <input {...props} ref={ref} />
    </InputPrefixContainer>
  );
});

export const InputPrice = (props) => {
  const {
    priceLabel,
    onChange,
    error = false,
    value: inputValue,
    name,
    isEdit,
    ...rest
  } = props;

  const [value, setValue] = useState('0.00');
  const inputPrice = useRef();
  const initialized = useRef();

  const handleChange = (ev) => {
    const { value, maskedValue } = mask(ev);
    ev.target.value = value;
    setValue(maskedValue);
    onChange(ev);
  };

  useEffect(() => {
    if (inputValue && isEdit && !initialized.current) {
      const formattedPrice = getInitialFormattedPrice(inputValue);
      handleChange({ target: { value: formattedPrice, name } });
      initialized.current = true;
    }
  }, [inputValue, isEdit, initialized.current, name]);

  const handleClick = () => {
    const input = inputPrice.current;
    const end = input.value.length;
    input.setSelectionRange(end, end);
    input.focus();
  };

  return (
    <InputPriceContainer>
      <ClipTypographyLabel error={+error}>{priceLabel}</ClipTypographyLabel>
      <ClipInputPrice
        {...rest}
        name={name}
        error={error}
        inputRef={inputPrice}
        labelsize={priceLabel.length}
        value={value}
        onChange={handleChange}
        onClick={handleClick}
        inputComponent={InputWithPrefix}
      />
    </InputPriceContainer>
  );
};
