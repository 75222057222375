import {
  theme,
  CardContent as ClipCardContent,
  IconButton as ClipIconButton,
  Tooltip as ClipTooltip
} from '@clipmx/clip-components';
import { useScreenSize } from 'helpers/hooks/useScreenSize';
import { useValueWithTimeout } from 'helpers/hooks/useValueWithTimeout';
import {
  ClipButtonStyled,
  ClipCardBottomStyled,
  ClipCardContentStyled,
  ClipTypographyBottomBarTitle,
  Divider,
  ItemSizeStyled,
  MobileButtonsContainer,
  SelectedItemsFixedContainer,
  SelectedItemsChild,
  getAnimatedValues
} from './styled';

const ClipIconButtonStyled = (props) => (
  <ClipIconButton
    color="transparent"
    size="medium"
    disableFocusRipple
    disableRipple
    variant="rounded"
    sx={{
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.15) !important'
      }
    }}
    {...props}
  />
);

export const FloatingBottomBar = (props) => {
  const { numberOfItems, primaryButton, secondaryButton, title } = props;
  const isOpen = useValueWithTimeout(false, numberOfItems > 0);
  const {
    text: textPrimaryBtn,
    onClick: onClickPrimaryBtn,
    icon: IconPrimaryBtn
  } = primaryButton || {};
  const {
    text: textSecondaryBtn,
    onClick: onClickSecondaryBtn,
    icon: IconSecondaryBtn
  } = secondaryButton || {};
  const screenSize = useScreenSize();

  const isMobile = () => {
    return screenSize <= theme.breakpoints.values.tablet;
  };

  if (!isOpen) {
    return null;
  }

  return (
    <>
      {isMobile() ? (
        <>
          <ClipCardBottomStyled
            sx={getAnimatedValues({
              isVisible: numberOfItems > 0,
              isMobile: true
            })}
          >
            <ClipCardContent>
              <ClipTypographyBottomBarTitle variant={'base'}>
                <ItemSizeStyled>{numberOfItems}</ItemSizeStyled>
                {title}
              </ClipTypographyBottomBarTitle>
              <Divider />
              <MobileButtonsContainer>
                {primaryButton && (
                  <ClipButtonStyled
                    color={'tertiary'}
                    variant={'contained'}
                    size={'large'}
                    onClick={onClickPrimaryBtn}
                  >
                    {IconPrimaryBtn && (
                      <IconPrimaryBtn
                        sx={(theme) => ({
                          color: theme.palette.surface[700],
                          marginRight: '12px',
                          pointerEvents: 'none'
                        })}
                      />
                    )}
                    {textPrimaryBtn}
                  </ClipButtonStyled>
                )}
                {secondaryButton && (
                  <ClipButtonStyled
                    color={'tertiary'}
                    variant={'contained'}
                    size={'large'}
                    onClick={onClickSecondaryBtn}
                  >
                    {IconSecondaryBtn && (
                      <IconSecondaryBtn
                        sx={(theme) => ({
                          color: theme.palette.surface[700],
                          marginRight: '12px',
                          pointerEvents: 'none'
                        })}
                      />
                    )}
                    {textSecondaryBtn}
                  </ClipButtonStyled>
                )}
              </MobileButtonsContainer>
            </ClipCardContent>
          </ClipCardBottomStyled>
        </>
      ) : (
        <SelectedItemsFixedContainer
          sx={getAnimatedValues({ isVisible: numberOfItems > 0 })}
        >
          <SelectedItemsChild borderRadius={2}>
            <ClipCardContentStyled>
              <ClipTypographyBottomBarTitle variant={'base'}>
                <ItemSizeStyled>{numberOfItems}</ItemSizeStyled>
                {title}
              </ClipTypographyBottomBarTitle>
            </ClipCardContentStyled>
            <ClipCardContent
              sx={{
                width: '20%',
                display: 'flex',
                paddingRight: theme.spacing(1),
                justifyContent: 'space-evenly',
                gap: theme.spacing(4)
              }}
            >
              {primaryButton && (
                <ClipTooltip arrow placement="top" title={textPrimaryBtn}>
                  <ClipTypographyBottomBarTitle>
                    <ClipIconButtonStyled onClick={onClickPrimaryBtn}>
                      {IconPrimaryBtn && (
                        <IconPrimaryBtn
                          sx={(theme) => ({
                            color: theme.palette.surface[50],
                            pointerEvents: 'none'
                          })}
                        />
                      )}
                    </ClipIconButtonStyled>
                  </ClipTypographyBottomBarTitle>
                </ClipTooltip>
              )}
              {secondaryButton && (
                <ClipTooltip arrow placement="top" title={textSecondaryBtn}>
                  <ClipTypographyBottomBarTitle>
                    <ClipIconButtonStyled onClick={onClickSecondaryBtn}>
                      {IconSecondaryBtn && (
                        <IconSecondaryBtn
                          sx={(theme) => ({
                            color: theme.palette.surface[50],
                            pointerEvents: 'none'
                          })}
                        />
                      )}
                    </ClipIconButtonStyled>
                  </ClipTypographyBottomBarTitle>
                </ClipTooltip>
              )}
            </ClipCardContent>
          </SelectedItemsChild>
        </SelectedItemsFixedContainer>
      )}
    </>
  );
};
