import styled from '@emotion/styled';
import {
  theme,
  CardContent as ClipCardContent,
  IconButton as ClipIconButton,
  Typography as ClipTypography
} from '@clipmx/clip-components';

export const ClipIconButtonAddCategory = styled(ClipIconButton)`
  width: 100%;
  height: ${theme.spacing(15)};
  display: flex;
  justify-content: space-between;
`;

export const InformationContainer = styled.div`
  display: flex;
  ${theme.breakpoints.down('tablet')} {
    flex-direction: column;
    align-items: center;
  }
`;

export const ClipCardContentStyled = styled(ClipCardContent)`
  display: flex;
  flex-direction: column;
  padding-right: ${theme.spacing(6)};
  ${theme.breakpoints.down('tablet')} {
    flex-direction: column;
    align-items: center;
  }
`;

export const FieldsContainer = styled.div`
  width: 100%;
  margin-left: ${theme.spacing(4)};

  ${theme.breakpoints.down('tablet')} {
    margin-left: 0;
    margin-top: ${theme.spacing(4)};
  }
`;

export const AddProductForm = styled.div`
  margin-bottom: 49px;
`;

export const Space = styled.div`
  height: ${theme.spacing(6)};
`;

export const SwipeableContent = styled.div``;

export const SwipeableInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing(6)} ${theme.spacing(4)};
`;

export const SwipeableListContainer = styled.div`
  max-height: 250px;
  overflow-y: auto;
`;

export const SwipeableTitle = styled(ClipTypography)`
  text-align: 'center';
  color: ${theme.palette.surface[950]};
  font-size: ${theme.typography.lg.fontSize};
  line-height: ${theme.typography.lg.lineHeight};
  font-weight: ${theme.typography.fontWeightMedium};
  margin: 0;

  ${[theme.breakpoints.down('tablet')]} {
    font-size: ${theme.typography.xl.fontSize};
    line-height: ${theme.typography.xl.lineHeight};
  }
`;

export const SwipeableDescription = styled(ClipTypography)`
  color: ${theme.palette.surface[700]};
  font-size: ${theme.typography.body1.fontSize};
  font-weight: ${theme.typography.fontWeightRegular};
  line-height: ${theme.typography.body1.lineHeight};
  margin-top: 8;

  ${[theme.breakpoints.down('tablet')]} {
    font-size: ${theme.typography.sm.fontSize};
    line-height: ${theme.typography.sm.lineHeight};
  }
`;

export const SwipeableActionContainer = styled.div`
  display: flex;
  padding-top: 24px;
`;

export const ConfirmContainer = styled.div`
  padding: 24px 16px;
`;
