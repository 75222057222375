import styled from '@emotion/styled';
import {
  theme,
  Typography as ClipTypography,
  IconButton as ClipIconButton
} from '@clipmx/clip-components';
import { importantCSS } from 'helpers/overwriteCSS';

export const ClipTypographySubtitle = styled(ClipTypography)`
  ${importantCSS(theme.typography.body1)};
  margin-bottom: 32px;

  ${theme.breakpoints.down('tablet')} {
    ${importantCSS(theme.typography.sm)};
  }
`;

export const ClipTypographyToogle = styled(ClipTypography)`
  ${importantCSS({
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.surface[950]
  })};
`;

export const ClipTypographyAddAtribute = styled(ClipTypography)`
  ${importantCSS({
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.surface[950]
  })};
  ${theme.breakpoints.down('tablet')} {
    ${importantCSS(theme.typography.sm)}
  }
`;

export const SwitchProductOptions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ViewAddAtribute = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 32px;
`;

export const ClipIconButtonAddAttribute = styled(ClipIconButton)`
  width: 100%;
  height: ${theme.spacing(15)};
  display: flex;
  justify-content: space-between;
`;
export const ClipTypographyAtribute = styled(ClipTypography)`
  ${importantCSS({
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.surface[950]
  })};
  ${theme.breakpoints.down('tablet')} {
    ${importantCSS(theme.typography.sm)}
  }
`;
