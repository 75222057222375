import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getCategoryByIdThunk,
  updateCategoryThunk
} from 'store/categories/thunks';
import { selectCategory } from 'store/categories/selectors';
import { getAllProductsThunk } from 'store/products/thunks';
import { CategoryContent } from 'components/CategoryContent/CategoryContent';
import { uploadImageThunk } from 'store/images/thunks';
import { fireNotification } from 'store/notifications/thunks';
import { SEVERITY } from 'components/Notification/Notification';
import { urls } from 'helpers/navigation';
import { text } from './text';

export const EditCategory = () => {
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const { categoryId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentCategory = useSelector(selectCategory);
  const [categoryProducts, setCategoryProducts] = useState([]);

  useEffect(() => {
    if (categoryId && categoryId !== currentCategory?.category_id) {
      dispatch(getCategoryByIdThunk(categoryId));
    }
  }, [categoryId, currentCategory]);

  useEffect(() => {
    dispatch(getAllProductsThunk());
  }, []);

  useEffect(() => {
    if (currentCategory && currentCategory?.products) {
      setCategoryProducts([...currentCategory.products]);
    }
  }, [currentCategory]);

  const handleUpdateCategory = async (categoryData, image) => {
    categoryData.image = currentCategory.image ? currentCategory.image : null;

    if (image) {
      const thunkImageResult = await dispatch(uploadImageThunk(image));
      categoryData.image = thunkImageResult?.payload;
    }

    categoryData.category_id = currentCategory.category_id;

    setUnsavedChanges(false);

    dispatch(
      updateCategoryThunk(
        categoryData,
        () => {
          dispatch(fireNotification(SEVERITY.success, text.categoryUpdated));
          navigate(urls.catalogList);
        },
        () => {
          dispatch(fireNotification(SEVERITY.error, text.categoryUpdatedError));
          navigate(urls.catalogList);
        }
      )
    );
  };

  return (
    <CategoryContent
      currentCategory={currentCategory}
      productList={{ categoryProducts, setCategoryProducts }}
      changes={{ unsavedChanges, setUnsavedChanges }}
      handleCategoryAction={handleUpdateCategory}
    />
  );
};
